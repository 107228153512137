import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import OtherContents from 'components/movePage';
import { getDetailInformation } from 'components/detail/dataManipulation';

import hack from 'assets/hack.png';
import IconArt from 'assets/image.png';
import IconVideo from 'assets/video.png';
import any from 'assets/hatsLogo_any.png';

const Template = ({ children }: { children: React.ReactNode }) => {
  const { index, title, dataType, url, description, beforeTitle, nextTitle } =
    getDetailInformation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);
  const { isPC, isTablet } = useSelector(
    ({ responsive }: { responsive: responsiveIState }) => responsive.screenType,
  );
  return (
    <Container>
      <CenterAlign isPC={isPC} isTablet={isTablet}>
        <TitleContainer isPC={isPC}>
          <Title>{title}</Title>
          {dataType.split('/')[0] === 'image' ? (
            <IconImage image={IconArt} />
          ) : dataType.split('/')[0] === 'video' ? (
            <IconImage image={IconVideo} />
          ) : dataType.split('/')[0] === 'hack' ? (
            <IconImage image={hack} />
          ) : dataType.split('/')[0] === 'any' ? (
            <IconImage image={any} />
          ) : (
            <IconImage image={any} />
          )}
        </TitleContainer>
        <DescriptionContainer isPC={isPC}>
          <Description>
            {description.split('\n').map((line: string, index: number) => {
              return (
                <span key={index}>
                  {line}
                  <div />
                </span>
              );
            })}
          </Description>
        </DescriptionContainer>
        {children}
        <OtherContents
          index={index}
          url={url}
          beforeTitle={beforeTitle}
          nextTitle={nextTitle}
          checkTablet={isTablet}
        />
      </CenterAlign>
    </Container>
  );
};
export default Template;

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`;
const CenterAlign = styled.div<{ isPC: boolean; isTablet: boolean }>`
  width: 100%;
  max-width: 900px;

  font-size: ${(props) =>
    props.isPC ? '1rem' : props.isTablet ? '1rem' : '3.5vw'};
  margin-top: 40px;
`;

const TitleContainer = styled.div<{ isPC: boolean }>`
  width: ${(props) => (props.isPC ? '90%' : '80%')};
  height: 10em;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: inherit;

  margin: auto;
`;

const Title = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  margin-right: 10%;
`;

const IconImage = styled.div`
  width: 3em;
  height: 3em;

  background-image: url(${(props: { image: string }) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: inherit;
`;

const DescriptionContainer = styled.div<{ isPC: boolean }>`
  width: ${(props) => (props.isPC ? '90%' : '80%')};
  font-size: inherit;

  margin: auto;
  margin-bottom: 7em;
`;

const Description = styled.div`
  width: 90%;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.5em;
`;
