import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import Slide from 'pages/hack/S1/laptopPhoto/laptop/carousel/slide';

import groupPhoto01 from 'assets/clues/S1/hack/01_02_groupPhoto_01.jpg';
import groupPhoto02 from 'assets/clues/S1/hack/01_02_groupPhoto_02.jpg';

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const PrevButton = styled.div`
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 20px 20px 0;
  user-select: none;
  cursor: pointer;
`;
const NextButton = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  user-select: none;
  border-radius: 20px 0 0 20px;
  cursor: pointer;
`;
const SlideContainer = styled.div`
  width: 200%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;
const PhotoTitle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2%;
  left: 3%;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  z-index: 2;
`;

const TOTAL_SLIDES = 1;
const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef<HTMLDivElement>(null);

  const nextSlide = () => {
    if (currentSlide >= TOTAL_SLIDES) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(TOTAL_SLIDES);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    if (slideRef.current !== null) {
      slideRef.current.style.transition = 'all 0.5s ease-in-out';
      slideRef.current.style.transform = `translateX(-${
        (currentSlide * 100) / 2
      }%)`;
    }
  }, [currentSlide]);

  return (
    <>
      <CarouselContainer>
        <PhotoTitle>{currentSlide + 1} / 2</PhotoTitle>
        <SlideContainer ref={slideRef}>
          <Slide photo={groupPhoto02} />
          <Slide photo={groupPhoto01} />
        </SlideContainer>
        <PrevButton onClick={prevSlide}>&#10094;</PrevButton>
        <NextButton onClick={nextSlide}>&#10095;</NextButton>
      </CarouselContainer>
    </>
  );
};
export default Carousel;
