import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import logo from 'assets/hatsLogo_any.png';
import question_01_02 from 'assets/result/question/question_01_02.png';

const QuestionPresenter: React.FC<RouteComponentProps> = ({ history }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = React.useState<AnswerFormIState>({
    year: '0000',
    month: '00',
    day: '00',
    hour: '00',
    minute: '00',
  });

  const isTablet = useMediaQuery({
    query: '(min-width: 700px)',
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    event.preventDefault();
    const name = event.target.name as string;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const enteredAnswer = `${state.year}-${state.month}-${state.day}-${state.hour}-${state.minute}`;
    if (enteredAnswer === '') return setErrorMessage('정답을 입력해주세요');
    else if (enteredAnswer === '2020-08-05-20-31')
      return history.push(`/vz69w7w9fvv5n4p9kphe/PGTLFM6S`);
    else return history.push(`/vz69w7w9fvv5n4p9kphe/5VSRG4WQ`);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  return (
    <Container>
      <CenterAlign>
        <MissionContainer>
          <Mission src={question_01_02} />
        </MissionContainer>
        <InputBoxAligner
          onSubmit={handleSubmit}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        >
          <InputBoxContainer isTablet={isTablet}>
            <div />
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="year" onChange={handleChange}>
                <Option value="0000">0000년</Option>
                <Option value="2020">2020년</Option>
                <Option value="2019">2019년</Option>
                <Option value="2018">2018년</Option>
              </Select>
            </SelectContainer>
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="month" onChange={handleChange}>
                <Option value="00">00월</Option>
                <Option value="01">01월</Option>
                <Option value="02">02월</Option>
                <Option value="03">03월</Option>
                <Option value="04">04월</Option>
                <Option value="05">05월</Option>
                <Option value="06">06월</Option>
                <Option value="07">07월</Option>
                <Option value="08">08월</Option>
                <Option value="09">09월</Option>
                <Option value="10">10월</Option>
                <Option value="11">11월</Option>
                <Option value="12">12월</Option>
              </Select>
            </SelectContainer>
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="day" onChange={handleChange}>
                <Option value="00">00일</Option>
                <Option value="01">01일</Option>
                <Option value="02">02일</Option>
                <Option value="03">03일</Option>
                <Option value="04">04일</Option>
                <Option value="05">05일</Option>
                <Option value="06">06일</Option>
                <Option value="07">07일</Option>
                <Option value="08">08일</Option>
                <Option value="09">09일</Option>
                <Option value="10">10일</Option>
                <Option value="11">11일</Option>
                <Option value="12">12일</Option>
                <Option value="13">13일</Option>
                <Option value="14">14일</Option>
                <Option value="15">15일</Option>
                <Option value="16">16일</Option>
                <Option value="17">17일</Option>
                <Option value="18">18일</Option>
                <Option value="19">19일</Option>
                <Option value="20">20일</Option>
                <Option value="21">21일</Option>
                <Option value="22">22일</Option>
                <Option value="23">23일</Option>
                <Option value="24">24일</Option>
                <Option value="25">25일</Option>
                <Option value="26">26일</Option>
                <Option value="27">27일</Option>
                <Option value="28">28일</Option>
                <Option value="29">29일</Option>
                <Option value="30">30일</Option>
                <Option value="31">31일</Option>
              </Select>
            </SelectContainer>
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="hour" onChange={handleChange}>
                <Option value="00">00시</Option>
                <Option value="01">01시</Option>
                <Option value="02">02시</Option>
                <Option value="03">03시</Option>
                <Option value="04">04시</Option>
                <Option value="05">05시</Option>
                <Option value="06">06시</Option>
                <Option value="07">07시</Option>
                <Option value="08">08시</Option>
                <Option value="09">09시</Option>
                <Option value="10">10시</Option>
                <Option value="11">11시</Option>
                <Option value="12">12시</Option>
                <Option value="13">13시</Option>
                <Option value="14">14시</Option>
                <Option value="15">15시</Option>
                <Option value="16">16시</Option>
                <Option value="17">17시</Option>
                <Option value="18">18시</Option>
                <Option value="19">19시</Option>
                <Option value="20">20시</Option>
                <Option value="21">21시</Option>
                <Option value="22">22시</Option>
                <Option value="23">23시</Option>
              </Select>
            </SelectContainer>
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="minute" onChange={handleChange}>
                <Option value="00">00분</Option>
                <Option value="01">01분</Option>
                <Option value="02">02분</Option>
                <Option value="03">03분</Option>
                <Option value="04">04분</Option>
                <Option value="05">05분</Option>
                <Option value="06">06분</Option>
                <Option value="07">07분</Option>
                <Option value="08">08분</Option>
                <Option value="09">09분</Option>
                <Option value="10">10분</Option>
                <Option value="11">11분</Option>
                <Option value="12">12분</Option>
                <Option value="13">13분</Option>
                <Option value="14">14분</Option>
                <Option value="15">15분</Option>
                <Option value="16">16분</Option>
                <Option value="17">17분</Option>
                <Option value="18">18분</Option>
                <Option value="19">19분</Option>
                <Option value="20">20분</Option>
                <Option value="21">21분</Option>
                <Option value="22">22분</Option>
                <Option value="23">23분</Option>
                <Option value="24">24분</Option>
                <Option value="25">25분</Option>
                <Option value="26">26분</Option>
                <Option value="27">27분</Option>
                <Option value="28">28분</Option>
                <Option value="29">29분</Option>
                <Option value="30">30분</Option>
                <Option value="31">31분</Option>
                <Option value="32">32분</Option>
                <Option value="33">33분</Option>
                <Option value="34">34분</Option>
                <Option value="35">35분</Option>
                <Option value="36">36분</Option>
                <Option value="37">37분</Option>
                <Option value="38">38분</Option>
                <Option value="39">39분</Option>
                <Option value="40">40분</Option>
                <Option value="41">41분</Option>
                <Option value="42">42분</Option>
                <Option value="43">43분</Option>
                <Option value="44">44분</Option>
                <Option value="45">45분</Option>
                <Option value="46">46분</Option>
                <Option value="47">47분</Option>
                <Option value="48">48분</Option>
                <Option value="49">49분</Option>
                <Option value="50">40분</Option>
                <Option value="51">51분</Option>
                <Option value="52">52분</Option>
                <Option value="53">53분</Option>
                <Option value="54">54분</Option>
                <Option value="55">55분</Option>
                <Option value="56">56분</Option>
                <Option value="57">57분</Option>
                <Option value="58">58분</Option>
                <Option value="59">59분</Option>
              </Select>
            </SelectContainer>
            <div />
            <LogoContainer>
              <Logo onClick={handleSubmit} />
            </LogoContainer>
          </InputBoxContainer>
        </InputBoxAligner>
        <MessageContainer>
          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </MessageContainer>
        <div />
      </CenterAlign>
    </Container>
  );
};
export default withRouter(QuestionPresenter);

interface AnswerFormIState {
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
}

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin: 1rem 0 5rem 0;
`;
const CenterAlign = styled.div`
  width: 100%;
  max-width: 900px;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MissionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 60px 0;
`;
const Mission = styled.img`
  min-width: 280px;
  width: 40%;
  max-width: 500px;
`;

const InputBoxAligner = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputBoxContainer = styled.div`
  min-width: 310px;
  width: 80%;
  max-width: 400px;
  height: 130%;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(9, 1fr);

  font-size: ${(props: { isTablet: boolean }) =>
    props.isTablet ? '16' : '12'}px;
  font-weight: bold;
  background-color: #595959;
  padding: 0.5rem 0;
  border-radius: 10px;
`;
const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${(props: { isTablet: boolean }) =>
    props.isTablet ? '0.3' : '0'}rem;
`;

const Select = styled.select`
  height: 100%;
  color: white;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  border: none;

  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

const Option = styled.option`
  text-align: center;
  color: black;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

const MessageContainer = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ErrorMessage = styled.div`
  color: red;
`;
