import React from 'react';
import styled from 'styled-components';

function CircleLoading(props: propsIState) {
  const { fill = '#113f88' } = props;
  return (
    <Container>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        fill={fill}
      >
        <path d="M19 50A31 31 0 0 0 81 50A31 34.3 0 0 1 19 50">
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 51.65;360 50 51.65"
          />
        </path>
      </SVG>
    </Container>
  );
}
export default CircleLoading;

interface propsIState {
  fill?: string;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const SVG = styled.svg<{ fill: string }>`
  fill: ${(props) => props.fill};
  shape-rendering: auto;
  margin: auto;
`;
