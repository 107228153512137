import React from 'react';
import styled from 'styled-components';

import PasswordPage from 'pages/hack/S1/jiyeonSmartWatch/SmartWatch/password';
import HealthInfoPage from 'pages/hack/S1/jiyeonSmartWatch/SmartWatch/main';

const WatchDivContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;
const WatchDivBox = styled.div`
  width: 310px;
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 3rem 0;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 70px;
  overflow: hidden;
`;

const PageBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 70px;
`;

const PasswordPageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
  color: white;

  &:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 1);
    z-index: -1;
  }
`;
const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '1' : '0'};
  z-index: ${(props) => (props.PasswordState ? '1' : '-1')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
`;

interface WatchIState {
  email: string;
  showPw: boolean;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const SmartWatch = ({
  email,
  showPw,
  password,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  InputRef,
}: WatchIState) => {
  return (
    <>
      <WatchDivContainer>
        <WatchDivBox>
          <PasswordPageContainer PasswordState={showPw}>
            <PasswordPage
              email={email}
              password={password}
              errorMessage={errorMessage}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              handleKeyPress={handleKeyPress}
              InputRef={InputRef}
            />
          </PasswordPageContainer>
          <PageContainer PasswordState={showPw}>
            {showPw && (
              <PageBox>
                <HealthInfoPage />
              </PageBox>
            )}
          </PageContainer>
        </WatchDivBox>
      </WatchDivContainer>
    </>
  );
};
export default SmartWatch;
