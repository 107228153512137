import styled, { css, keyframes } from 'styled-components';

const appear = keyframes`
    0% {
      opacity: 0;
      /* transform: translate3d(0, 100%, 0); */
    }
    100% {
      opacity: 1;
      /* transform: translate3d(0, 0, 0); */
    }
    `;
const disappear = keyframes`
    0% {
      opacity: 1;
      /* transform: translate3d(0, 0, 0); */
    }
    100% {
      opacity: 0;
        /* transform: translate3d(0, -100%, 0); */
    }
`;

const Container = styled.div<{ pageIn: boolean }>`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background-color: black;
  ${(props) =>
    props.pageIn
      ? css`
          /* transform: translate3d(0, 100%, 0); */
          animation: ${appear} 0.5s ease-out forwards;
        `
      : css`
          /* transform: translate3d(0, 0, 0); */
          animation: ${disappear} 0.7s ease-out forwards;
        `}
  z-index: 999;
`;

const Background = styled.img<{ isPC: boolean; isTablet: boolean }>`
  width: ${(props) => (props.isPC ? '40%' : props.isTablet ? '60%' : '100%')};
  height: ${(props) => (props.isPC ? '60%' : props.isTablet ? '70%' : '80%')};

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate3d(-50%, -50%, 0);

  object-fit: contain;
`;

export { Container, Background };
