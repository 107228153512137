import React from 'react';
import styled from 'styled-components';

import VideoPlayer from 'components/Video';

import investigationReport_01_04 from 'assets/result/investigationReport/investigationReport_01_04.png';
import finalLetter_01_04 from 'assets/result/finalLetter/finalLetter_01_04.png';
import dataArchiveLogo from 'assets/full_background_data_archive_logo.jpg';
import finalVideo_01_04 from 'assets/result/finalVideo/finalVideo_01_04.mp4';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0.3rem 1rem;
`;
const CenterAlign = styled.div`
  width: 100%;
  max-width: 900px;
`;

const InnerBox = styled.div`
  line-height: 1.3rem;
  padding: 2rem 0 1.3rem 0;
`;

const LetterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
`;
const VideoContainer = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
`;

const InvestigationContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 3rem;
`;

const Photo = styled.img`
  width: 100%;
  max-width: 900px;
  background-color: black;
`;

const ManualResult = () => {
  const videoOptions = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: finalVideo_01_04,
        type: 'video/mp4',
      },
    ],
    width: window.innerWidth >= 900 ? 810 : window.innerWidth * 0.9,
    poster: dataArchiveLogo,
  };

  return (
    <Container>
      <CenterAlign>
        <InnerBox>
          <LetterContainer>
            <Photo src={finalLetter_01_04} />
          </LetterContainer>
          <VideoContainer>
            <VideoPlayer {...videoOptions} />
          </VideoContainer>
          <InvestigationContainer>
            <Photo src={investigationReport_01_04} />
          </InvestigationContainer>
        </InnerBox>
      </CenterAlign>
    </Container>
  );
};
export default ManualResult;
