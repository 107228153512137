import React, { useState, useEffect, Suspense, lazy } from 'react';
import styled from 'styled-components';

import Template from 'components/detail/template';
import Loading from 'components/loading/circleLoading';

import murder_article_01_01 from 'assets/clues/S1/clue/image/01_murderArticle.jpg';
const YouTubePlayer = lazy(() => import('components/Video/youtube'));

const DetailPresenter = () => {
  const [youtubeVideoID] = useState('LirL0XdUuEQ');
  const [youtubeWidth, setYoutubeWidth] = useState('0');
  const [youtubeHeight, setYoutubeHeight] = useState('0');
  const [youtubeAutoPlay] = useState(0);

  useEffect(() => {
    setYoutubeWidth(`${window.innerWidth}`);
    if (window.innerWidth * 0.9 > 320 && window.innerWidth < 900) {
      setYoutubeWidth(`${window.innerWidth * 0.9}`);
    } else if (window.innerWidth >= 900) {
      setYoutubeWidth('900');
    }
    setYoutubeHeight(`${(parseFloat(youtubeWidth) / 640) * 390}`);
  }, [youtubeWidth]);

  return (
    <Template>
      <Suspense
        fallback={
          <LoadingContainer>
            <LoadingAnimationContainer>
              <Loading fill="#330000" />
            </LoadingAnimationContainer>
          </LoadingContainer>
        }
      >
        <YoutubeContainer>
          {youtubeWidth !== '0' && (
            <YouTubePlayer
              videoID={youtubeVideoID}
              width={youtubeWidth}
              height={youtubeHeight}
              autoPlay={youtubeAutoPlay}
            />
          )}
        </YoutubeContainer>
        <PhotoContainer>
          <Photo src={murder_article_01_01} />
        </PhotoContainer>
      </Suspense>
    </Template>
  );
};
export default DetailPresenter;

const YoutubeContainer = styled.div`
  max-width: 900px;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
`;
const PhotoContainer = styled.div`
  max-width: 900px;
  display: flex;
  justify-content: center;
`;
const Photo = styled.img`
  width: 100%;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingAnimationContainer = styled.div`
  width: 50%;
  height: 30%;
`;
