import React from 'react';
import styled from 'styled-components';

import VideoPlayer from 'components/Video';
import Password from 'pages/hack/S1/gyeongsusblackbox/password';

import logo from 'assets/clues/S1/hack/04_BlackBoxLogo.png';
import gyeongsuBlackBox from 'assets/clues/S1/hack/04_gyeongsu_blackbox.mp4';
import Template from 'components/detail/template';
import { useSelector } from 'react-redux';

const DetailPresenter = ({
  showPw,
  Id,
  password,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  InputRef,
}: DataIState) => {
  const { isPC } = useSelector(
    ({ responsive }: { responsive: responsiveIState }) => responsive.screenType,
  );

  const videoOptions = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: gyeongsuBlackBox,
        type: 'video/mp4',
      },
    ],
    width: window.innerWidth > 900 ? 810 : window.innerWidth * 0.9,
    poster: logo,
  };

  return (
    <Template>
      <NoteDivBox checkTablet={!isPC}>
        <PasswordPage PasswordState={showPw}>
          <Password
            Id={Id}
            password={password}
            checkTablet={!isPC}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleKeyPress={handleKeyPress}
            InputRef={InputRef}
          />
        </PasswordPage>
        <VideoContainer PasswordState={showPw}>
          {showPw && (
            <>
              <VideoDate>
                <div>8월 5일 블랙박스 영상</div>
              </VideoDate>
              <VideoPlayer {...videoOptions} />
            </>
          )}
        </VideoContainer>
      </NoteDivBox>
    </Template>
  );
};
export default DetailPresenter;
interface DataIState {
  showPw: boolean;
  Id: string;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const NoteDivBox = styled.div<{ checkTablet: boolean }>`
  width: ${(props) => (props.checkTablet ? window.innerWidth : 900)};
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 3rem 0;
`;

const PasswordPage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.4s ease-in-out, z-index 0.4s ease-in-out;

  background-color: white;
  color: black;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '1' : '0'};
  z-index: ${(props) => (props.PasswordState ? '1' : '-1')};
  padding-bottom: 3rem;
  transition: opacity 0.4s ease-in-out;
`;
const VideoDate = styled.div`
  width: 50%;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  border-radius: 5px;
  margin: 3rem;
`;
