import React from 'react';
import styled, { keyframes } from 'styled-components';
import Input from 'pages/hack/S1/bohyunHotel/hotel/password/Input';

import hotelMain from 'assets/clues/S1/hack/04_hotelMain.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(104, 89, 84);
`;

const CenterAlign = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '100%' : '900px'};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const PassErrorContainer = styled.div`
  width: 320px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PasswordBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${hotelMain});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const shake = keyframes`
  0%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  25%{
    transform: translateX(3px);
    font-weight: 700;
  }
  50%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  75%{
    transform: translateX(2px);
    font-weight: 700;
  }
  100%{
    transform: translateX(0px);
  }
`;
const ErrorMessage = styled.div`
  position: absolute;
  bottom: 38%;
  color: #990000;
  animation: ${shake} 0.2s linear;
  margin-top: 1rem;
`;

interface PasswordIState {
  password: string;
  checkTablet: boolean;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  password,
  checkTablet,
  errorMessage,
  handleInputChange,
  InputRef,
}: PasswordIState) => {
  return (
    <Container>
      <CenterAlign checkTablet={checkTablet}>
        <PassErrorContainer>
          <PasswordBox>
            <Input
              checkTablet={checkTablet}
              name="Password"
              placeholder="Password"
              value={password}
              handleInputChange={handleInputChange}
              type="password"
              InputRef={InputRef}
            />
          </PasswordBox>
          {errorMessage === '' ? null : (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </PassErrorContainer>
      </CenterAlign>
    </Container>
  );
};
export default Password;
