import React, { useState } from 'react';
import Template from 'components/detail/template';
import Card from 'pages/hack/S1/sewonCard/Card';

const DetailHack = () => {
  const [showPw, setShowPw] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setErrorMessage('');
    if (name === 'password') {
      setPassword(value);
      if (value.length >= 4) {
        setPassword(value.substring(0, 4));
      }
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (password === '0719') {
      setShowPw(true);
      return;
    }

    setErrorMessage('비밀번호를 확인해주세요');
    return;
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <Template>
      <Card
        showPw={showPw}
        password={password}
        errorMessage={errorMessage}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        handleKeyPress={handleKeyPress}
      />
    </Template>
  );
};
export default DetailHack;
