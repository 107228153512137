import React from 'react';
import styled from 'styled-components';

import Password from 'pages/hack/S1/laptopPhoto02/laptop/password';
import background from 'assets/clues/S1/hack/01_laptopBackground.jpg';
import folder from 'assets/clues/S1/hack/01_laptopFolder.png';

interface MainIState {
  checkTablet: boolean;
  showPwBox: boolean;
  password: string;
  wrongPw: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  handleShowPwBox: () => void;
  handleBlockPwBox: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const MainPage = ({
  checkTablet,
  showPwBox,
  password,
  wrongPw,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  handleShowPwBox,
  handleBlockPwBox,
  inputRef,
}: MainIState) => {
  return (
    <Container>
      <FolderContainer checkTablet={checkTablet} onClick={handleShowPwBox}>
        <Folder />
        <FolderText>새 폴더</FolderText>
      </FolderContainer>
      {showPwBox ? (
        <>
          <BackgroundBlur />
          <Password
            checkTablet={checkTablet}
            password={password}
            wrongPw={wrongPw}
            handleKeyPress={handleKeyPress}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleBlockPwBox={handleBlockPwBox}
            inputRef={inputRef}
          />
        </>
      ) : null}
    </Container>
  );
};

export default MainPage;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: white;
`;
const FolderContainer = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '14' : '12'}%;
  height: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '14' : '12'}%;

  position: absolute;
  left: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '30' : '15'}px;

  top: 10%;
  cursor: pointer;
`;

const Folder = styled.div`
  width: 100%;
  height: 80%;
  background-image: url(${folder});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const FolderText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`;

const BackgroundBlur = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;
