import React from 'react';
import styled from 'styled-components';

import Template from 'components/detail/template';
import VideoPlayer from 'components/Video';
import Password from 'pages/hack/S1/jinchulRecoding/password';

import recoding from 'assets/clues/S1/hack/04_recording.png';
import jinchulRecord from 'assets/clues/S1/hack/04_jinchulRecord.mp4';

const DetailPresenter = ({
  isPC,
  showPw,
  password,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  InputRef,
}: DataIState) => {
  const videoOptions = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: jinchulRecord,
        type: 'video/mp4',
      },
    ],
    width: isPC ? 810 : window.innerWidth * 0.9,
    poster: recoding,
  };

  return (
    <Template>
      <NoteDivBox>
        <PasswordPage PasswordState={showPw}>
          <Password
            password={password}
            isPC={isPC}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleKeyPress={handleKeyPress}
            InputRef={InputRef}
          />
        </PasswordPage>
        <VideoContainer PasswordState={showPw}>
          {showPw && <VideoPlayer {...videoOptions} />}
        </VideoContainer>
      </NoteDivBox>
    </Template>
  );
};
export default DetailPresenter;

interface DataIState {
  isPC: boolean;
  showPw: boolean;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const NoteDivBox = styled.div`
  width: 100%;
  max-width: 900px;
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);

  overflow: hidden;
  margin: 3rem 0;
`;

const PasswordPage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.4s ease-in-out, z-index 0.4s ease-in-out;

  background-color: #070707;
  color: black;
`;

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '1' : '0'};
  z-index: ${(props) => (props.PasswordState ? '1' : '-1')};
  transition: opacity 0.4s ease-in-out;
`;
