import React from 'react';
import styled from 'styled-components';

import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';

import PasswordDiv from 'pages/hack/S1/jiyeonNote/password';
import DiaryPage from 'pages/hack/S1/jiyeonNote/DiaryPage';

import statusBlue from 'assets/clues/S1/hack/01_statusBarBlue_JY.jpg';
import firstPageImage from 'assets/clues/S1/hack/01_jiyeonDiary_01.png';
import secondPageImage from 'assets/clues/S1/hack/01_jiyeonDiary_02.png';

const NoteDivContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NoteDivBox = styled.div`
  width: 300px;
  height: 680px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 3rem 0;
  overflow: hidden;
`;

const PageBox = styled.div`
  width: 300px;
  height: 550px;
  position: relative;
  background-color: #e2e2e2;
`;

const PasswordPage = styled.div`
  width: 300px;
  height: 550px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;

  background-color: rgba(0, 0, 0, 1);
  color: white;
`;
const FirstPageContainer = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: ${(props: { firstPage: boolean; PasswordState: boolean }) =>
    props.PasswordState ? '1' : '0'};
  z-index: ${(props) => (props.PasswordState ? '1' : '-1')};
  transition: transform 0.15s ease-in-out, opacity 0.2s ease-in-out,
    z-index 0.2s ease-in-out;
  transform: ${(props) =>
    props.firstPage ? 'translateX(-100%)' : 'translateX(0%)'};
`;
const StatusBarBlue = styled.div`
  width: 300px;
  height: 20px;
  top: 50px;
  position: absolute;
  background-image: url(${statusBlue});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  z-index: 3;
`;

const SecondPageContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props: { firstPage: boolean }) =>
    props.firstPage ? 'translateX(0%)' : 'translateX(100%)'};
  transition: transform 0.15s ease-in-out;
  overflow: hidden;
`;
const CircleButton = styled.div`
  width: 40px;
  height: 40px;

  position: absolute;
  right: 10px;
  bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  opacity: 50%;
  z-index: 1;
  cursor: pointer;
`;

interface NoteIState {
  pass: boolean;
  firstPage: boolean;
  setPage: React.Dispatch<React.SetStateAction<boolean>>;
  password: string;
  errorMessage: string;
  handleNumberClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSubmit: () => void;
}

const PhoneNoteInputBox = ({
  pass,
  firstPage,
  setPage,
  password,
  errorMessage,
  handleNumberClick,
  handleSubmit,
}: NoteIState) => {
  return (
    <>
      <NoteDivContainer>
        <NoteDivBox>
          <StatusBarBlue />
          <PasswordPage PasswordState={pass}>
            <PasswordDiv
              password={password}
              errorMessage={errorMessage}
              handleNumberClick={handleNumberClick}
              handleSubmit={handleSubmit}
            />
          </PasswordPage>
          {pass && (
            <>
              <FirstPageContainer firstPage={firstPage} PasswordState={pass}>
                <PageBox>
                  <DiaryPage image={firstPageImage} />
                </PageBox>
                <CircleButton
                  onClick={() => {
                    setPage(!firstPage);
                  }}
                >
                  <KeyboardArrowRightOutlinedIcon fontSize="large" />
                </CircleButton>
              </FirstPageContainer>
              <SecondPageContainer firstPage={firstPage}>
                <PageBox>
                  <DiaryPage image={secondPageImage} />
                </PageBox>
                <CircleButton
                  onClick={() => {
                    setPage(!firstPage);
                  }}
                >
                  <KeyboardArrowLeftOutlinedIcon fontSize="large" />
                </CircleButton>
              </SecondPageContainer>
            </>
          )}
        </NoteDivBox>
      </NoteDivContainer>
    </>
  );
};
export default PhoneNoteInputBox;
