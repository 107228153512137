import React from 'react';
import styled, { keyframes } from 'styled-components';

import Input from 'pages/hack/S1/sewonCard/Card/password/Input';
import specification from 'assets/clues/S1/hack/04_cardSpecification_empty.jpg';
import logo from 'assets/clues/S1/hack/04_cardLogo.png';

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: repeat(9, 1fr);
  grid-template-columns: repeat(1);
  place-items: center;

  background-image: url(${specification});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  color: white;
`;
const Logo = styled.div`
  width: 40%;
  height: 100%;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const PasswordContainer = styled.div`
  display: block;
`;
const shake = keyframes`
  0%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  25%{
    transform: translateX(3px);
    font-weight: 700;
  }
  50%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  75%{
    transform: translateX(2px);
    font-weight: 700;
  }
  100%{
    transform: translateX(0px);
  }
`;
const ErrorConatiner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ErrorMessage = styled.div`
  position: absolute;
  bottom: 10%;
  color: #990000;
  animation: ${shake} 0.2s linear;
  margin-top: 1rem;
`;

interface PasswordIState {
  checkTablet: boolean;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
}

const PasswordPage = ({
  checkTablet,
  password,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
}: PasswordIState) => {
  return (
    <Container>
      <div />
      <div />
      <div />
      <Logo />
      <div />
      <PasswordContainer>
        <form
          onKeyPress={handleKeyPress}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Input
            checkTablet={checkTablet}
            name="password"
            placeholder="Password"
            value={password}
            type="password"
            handleInputChange={handleInputChange}
          />
        </form>
      </PasswordContainer>
      {errorMessage === '' ? null : (
        <ErrorConatiner>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorConatiner>
      )}
    </Container>
  );
};
export default PasswordPage;
