import React from 'react';
import styled, { keyframes } from 'styled-components';

import Input from 'pages/hack/S1/jiyeonSmartWatch/SmartWatch/password/roundedInput';
import logo from 'assets/clues/S1/hack/02_jiyeonSmartWatchLogo.png';

const Container = styled.div`
  width: 300px;
  height: 500px;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(1);
  place-items: center;
  border-radius: 70px;
  color: white;
`;
const Logo = styled.div`
  width: 120px;
  height: 100%;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const PasswordContainer = styled.div`
  display: block;
`;
const shake = keyframes`
  0%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  25%{
    transform: translateX(3px);
    font-weight: 700;
  }
  50%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  75%{
    transform: translateX(2px);
    font-weight: 700;
  }
  100%{
    transform: translateX(0px);
  }
`;
const ErrorConatiner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ErrorMessage = styled.div`
  position: absolute;
  bottom: 10%;
  color: #990000;
  animation: ${shake} 0.2s linear;
  margin-top: 1rem;
`;

const Button = styled.div`
  width: 80px;
  height: 40px;
  grid-row: span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  filter: opacity(50%);
  cursor: pointer;
`;

interface PasswordIState {
  email: string;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const PasswordPage = ({
  email,
  password,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  InputRef,
}: PasswordIState) => {
  return (
    <Container>
      <div />
      <Logo />
      <div />
      <PasswordContainer>
        <form
          onKeyPress={handleKeyPress}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Input
            name="email"
            placeholder="Email"
            value={email}
            type="text"
            handleInputChange={handleInputChange}
            InputRef={InputRef}
          />
          <Input
            name="password"
            placeholder="Password"
            value={password}
            type="password"
            handleInputChange={handleInputChange}
            InputRef={InputRef}
          />
        </form>
      </PasswordContainer>
      <Button onClick={handleSubmit}>Login</Button>
      {errorMessage === '' ? null : (
        <ErrorConatiner>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorConatiner>
      )}
    </Container>
  );
};
export default PasswordPage;
