import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Header from 'components/other/S1/header';
import docFolder from 'assets/clues/S1/other/seungminUSB/etc/docFolder.png';
import imageFolder from 'assets/clues/S1/other/seungminUSB/etc/imageFolder.png';
import videoFolder from 'assets/clues/S1/other/seungminUSB/etc/videoFolder.png';

const DataPresenter = ({ dataList }: dataPresentIState) => {
  const history = useHistory();

  function moveTo(index: number) {
    history.push(`/vz69w7w9fvv5n4p9kphe/usb/f2ebuv/${index}`);
  }
  const checkTablet = useMediaQuery({
    query: '(max-width: 1000px)',
  });
  return (
    <>
      <Header />
      <Container>
        <CenterAlign checkTablet={checkTablet}>
          <Grid checkTablet={checkTablet}>
            {dataList &&
              dataList.map((data: dataIState) => (
                <Folder key={data.index}>
                  {data.type.startsWith('docs') ? (
                    <Image
                      image={docFolder}
                      onClick={() => moveTo(data.index)}
                    />
                  ) : data.type.startsWith('image') ? (
                    <Image
                      image={imageFolder}
                      onClick={() => moveTo(data.index)}
                    />
                  ) : (
                    <Image
                      image={videoFolder}
                      onClick={() => moveTo(data.index)}
                    />
                  )}
                  <Title onClick={() => moveTo(data.index)}>{data.title}</Title>
                </Folder>
              ))}
          </Grid>
        </CenterAlign>
      </Container>
    </>
  );
};
export default DataPresenter;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${window.innerHeight - 50}px;
  display: flex;
  justify-content: center;
  padding: 1.4rem 1rem;
  background-color: rgba(245, 245, 245, 1);
`;
const CenterAlign = styled.div`
  width: 100%;
  max-width: 900px;
  padding: ${(props: { checkTablet: boolean }) =>
      props.checkTablet ? '2' : '5'}rem
    2rem;
`;
const Grid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-row-gap: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '40' : '50'}px;
  grid-column-gap: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '25' : '31'}px;
`;

const Folder = styled.div`
  width: 250px;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;

  &:focus {
    outline: none;
  }
  -webkit-tap-highlight-color: transparent;
`;

const Image = styled.div`
  width: 200px;
  height: 124px;
  background: url(${(props: { image: string }) => props.image}) no-repeat center;
  background-size: contain;
  cursor: pointer;
  filter: opacity(0.8);
`;

const Title = styled.span`
  cursor: pointer;
`;

interface dataPresentIState {
  dataList: Array<dataIState>;
}
interface dataIState {
  index: number;
  title: string;
  type: string;
}
