import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import InputBar from 'components/input/answerInput';

import confirmButtonSentence from 'assets/result/confirmButtonSentence.png';
import cancelButtonSentence from 'assets/result/cancelButtonSentence.png';
import question_01_04 from 'assets/result/question/question_01_04.png';
import episodeEndingCheckList_01_04 from 'assets/result/episodeEndingCheckList/episodeEndingCheckList_01_04.png';

const QuestionPresenter = () => {
  const history = useHistory();
  const [answer, setAnswer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);

  const togleModal = () => {
    setShowConfirmMessage(!showConfirmMessage);
    if (document.getElementsByTagName('body')[0].style.overflow === 'hidden') {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
  };
  const goToResultPage = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    history.push(`/fbh5kyy963gzhv2nzppn/E8UCK6ZP`);
  };
  const handleInputAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAnswer(value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (answer === '') return setErrorMessage('정답을 입력해주세요');
    else if (answer === '최시우') return setShowConfirmMessage(true);
    else return history.push(`/fbh5kyy963gzhv2nzppn/MC7LV8U8`);
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  return (
    <Container>
      {showConfirmMessage && (
        <>
          <ConfirmMessageContainer>
            <ConfirmBackground onClick={togleModal} />
            <Modal>
              <ConfirmMessage src={episodeEndingCheckList_01_04} />
              <ConfirmMessageButtonContainer>
                <ConfirmMessageButton onClick={goToResultPage} />
                <CancelMessageButton onClick={togleModal} />
              </ConfirmMessageButtonContainer>
            </Modal>
          </ConfirmMessageContainer>
        </>
      )}

      <CenterAlign>
        <MissionContainer>
          <Mission src={question_01_04} />
        </MissionContainer>
        <form
          onSubmit={handleSubmit}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        >
          <InputBox>
            <InputBar
              width={300}
              height={50}
              name="answer"
              value={answer}
              onChange={handleInputAnswer}
              onSubmit={handleSubmit}
            />
          </InputBox>
        </form>
        <MessageContainer>
          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </MessageContainer>
        <div />
      </CenterAlign>
    </Container>
  );
};

export default QuestionPresenter;

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin: 1rem 0 5rem 0;
`;
const ConfirmMessageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;
const ConfirmBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 2;
`;

const Modal = styled.div`
  max-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: black;
  z-index: 3;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const ConfirmMessage = styled.img`
  min-width: 300px;
  width: 50%;
  max-width: 600px;
  max-height: 80vh;
  padding: 20px;
  margin-bottom: 30px;
`;

const ConfirmMessageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ConfirmMessageButton = styled.div`
  width: 80%;
  background-color: white;
  background-image: url(${confirmButtonSentence});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-bottom: 10%;
  margin-bottom: 15px;
  cursor: pointer;

  transition: background-color 0.15s linear;
  &:hover {
    background-color: #808080;
  }
`;
const CancelMessageButton = styled.div`
  width: 80%;
  background-color: white;
  background-image: url(${cancelButtonSentence});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-bottom: 10%;
  margin-bottom: 30px;
  cursor: pointer;

  transition: background-color 0.15s linear;
  &:hover {
    background-color: #808080;
  }
`;

const CenterAlign = styled.div`
  width: 100%;
  max-width: 900px;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MissionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 60px 0;
`;
const Mission = styled.img`
  min-width: 280px;
  width: 40%;
  max-width: 500px;
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const MessageContainer = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ErrorMessage = styled.div`
  color: red;
`;
