import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Background } from './style';
import bg from 'assets/transitionLoading.gif';

interface propsIState {
  pageIn: boolean;
}

function PageTransition(props: propsIState) {
  const { pageIn } = props;
  const { isPC, isTablet } = useSelector(
    ({ responsive }: { responsive: responsiveIState }) => responsive.screenType,
  );

  return (
    <Container pageIn={pageIn}>
      <Background src={bg} isPC={isPC} isTablet={isTablet} />
    </Container>
  );
}

export default PageTransition;
