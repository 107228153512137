import React from 'react';
import styled from 'styled-components';
import logo from 'assets/hatsLogo_any.png';

const Container = styled.div`
  width: ${(props: { width: number; height: number }) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #595959;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
  margin-bottom: 1rem;
`;

const InputBarContainer = styled.div`
  width: 85%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Input = styled.input`
  width: 89%;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  :focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0);
  }
  margin-right: 3%;
`;
const LogoContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-start;
`;
const Logo = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

interface InputBar {
  width: number;
  height: number;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => void;
}

const InputBar = ({
  width,
  height,
  name,
  value,
  onChange,
  onSubmit,
}: InputBar) => {
  return (
    <Container width={width} height={height}>
      <InputBarContainer>
        <Input type="text" name={name} value={value} onChange={onChange} />
      </InputBarContainer>
      <LogoContainer>
        <Logo onClick={onSubmit} />
      </LogoContainer>
    </Container>
  );
};
export default InputBar;
