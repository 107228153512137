import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 10rem;
  height: 1.5rem;
  position: absolute;
  bottom: 42%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Input = styled.input`
  width: 10rem;
  height: 1.5rem;
  font-size: 15px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  text-align: center;
  letter-spacing: 5px;
  margin-right: -5px;

  &:focus {
    outline: none;
  }
`;

interface PasswordIState {
  checkTablet: boolean;
  name: string;
  placeholder: string;
  value: string;
  type: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  name,
  placeholder,
  value,
  type,
  handleInputChange,
  InputRef,
}: PasswordIState) => {
  return (
    <Container>
      <Input
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        type={type}
        ref={InputRef}
      />
    </Container>
  );
};
export default Password;
