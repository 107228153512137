import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import close from 'assets/clues/S1/hack/01_closeButton.png';
import Input from 'pages/hack/S1/laptopPhoto/laptop/password/boxInput';

const Container = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '70%' : '40%'};
  height: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '50%' : '40%'};
  color: white;
  z-index: 2;
`;
const CloseBox = styled.div`
  width: 100%;
  height: 18px;
  position: relative;
`;
const Line = styled.div``;
const CloseButton = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  right: 20px;
  background-image: url(${close});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  filter: opacity(0.7);
  cursor: pointer;
`;

const PasswordContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const shake = keyframes`
      0%{
        transform: translateX(-3px);
        font-weight: 700;
      }
      25%{
        transform: translateX(3px);
        font-weight: 700;
      }
      50%{
        transform: translateX(-3px);
        font-weight: 700;
      }
      75%{
        transform: translateX(2px);
        font-weight: 700;
      }
      100%{
        transform: translateX(0px);
      }
    `;
const ConsoleBox = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(9, 1fr);
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  animation: ${(props: { wrongPw: boolean }) =>
    props.wrongPw
      ? css`
          ${shake} 0.2s linear
        `
      : ''};
  background-color: white;
  border-radius: 8px;
`;

const PwTitle = styled.div`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
`;
const HintText = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.2);
  font-weight: 500;
  color: #ff0000;
  text-align: center;
  margin-top: 10px;
`;
interface PasswordIState {
  checkTablet: boolean;
  password: string;
  wrongPw: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  handleBlockPwBox: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const PasswordPage = ({
  checkTablet,
  password,
  wrongPw,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  handleBlockPwBox,
  inputRef,
}: PasswordIState) => {
  return (
    <Container checkTablet={checkTablet}>
      <ConsoleBox wrongPw={wrongPw}>
        <CloseBox>
          <CloseButton onClick={handleBlockPwBox} />
        </CloseBox>
        <Line />
        <div />
        <PwTitle>비밀번호를 입력해주세요</PwTitle>
        <HintText>힌트 : 재일고 설립년도</HintText>
        <div />
        <PasswordContainer
          onKeyPress={handleKeyPress}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Input
            checkTablet={checkTablet}
            password={password}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            inputRef={inputRef}
          />
        </PasswordContainer>

        <div />
      </ConsoleBox>
    </Container>
  );
};
export default PasswordPage;
