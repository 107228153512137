import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '70' : '50'}%;
  height: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '100' : '150'}%;
  display: flex;
  align-items: center;
  position: relative;

  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
`;

const InputBarContainer = styled.div`
  width: 85%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Input = styled.input`
  width: 89%;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  :focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0);
  }
  margin-right: 3%;
`;
const LogoContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-start;
`;
const Logo = styled.div`
  width: 35px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  cursor: pointer;
`;
interface PasswordIState {
  checkTablet: boolean;
  password: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  checkTablet,
  password,
  handleInputChange,
  handleSubmit,
  inputRef,
}: PasswordIState) => {
  return (
    <Container checkTablet={checkTablet}>
      <InputBarContainer>
        <Input
          name="password"
          type="password"
          placeholder="password"
          value={password}
          onChange={handleInputChange}
          ref={inputRef}
        />
      </InputBarContainer>
      <LogoContainer>
        <Logo onClick={handleSubmit}>&#10095;</Logo>
      </LogoContainer>
    </Container>
  );
};
export default Password;
