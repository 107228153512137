import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ShowList = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
`;

const OtherContentsContainer = styled.div`
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Contents = styled.div`
  width: 90%;
  margin: 3rem 0;
`;
const OterTitleContainer = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(36, 36, 36, 1);
  :nth-child(1) {
    border-top: 1px solid rgba(36, 36, 36, 1);
  }
`;

const OtherTitleType = styled.div`
  width: 4rem;
  display: inline-block;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 1rem;
`;
const OtherTitleBox = styled.div`
  display: inline-block;
  width: 100%;
`;
const OtherTitleText = styled.span`
  cursor: pointer;
`;

interface DataIState {
  index: number;
  url: string;
  beforeTitle: string;
  nextTitle: string;
  checkTablet: boolean;
}
const OtherContents = ({
  index,
  url,
  beforeTitle,
  nextTitle,
  checkTablet,
}: DataIState) => {
  return (
    <OtherContentsContainer>
      <ShowList>
        <Link to={`/${url}/data`}>목록 보기</Link>
      </ShowList>
      <Contents>
        {beforeTitle !== '' && (
          <OterTitleContainer>
            <OtherTitleType>이전글</OtherTitleType>
            <OtherTitleBox>
              <Link to={`/${url}/detail/${index - 1}`}>
                <OtherTitleText>
                  {checkTablet
                    ? beforeTitle
                    : beforeTitle.length > 20
                    ? beforeTitle.substring(0, 20) + '...'
                    : beforeTitle}
                </OtherTitleText>
              </Link>
            </OtherTitleBox>
          </OterTitleContainer>
        )}
        {nextTitle !== '' && (
          <OterTitleContainer>
            <OtherTitleType>다음글</OtherTitleType>
            <OtherTitleBox>
              <Link to={`/${url}/detail/${index + 1}`}>
                {checkTablet
                  ? nextTitle.length > 20
                    ? nextTitle
                    : nextTitle.substring(0, 20) + '...'
                  : nextTitle}
              </Link>
            </OtherTitleBox>
          </OterTitleContainer>
        )}
      </Contents>
    </OtherContentsContainer>
  );
};
export default OtherContents;
