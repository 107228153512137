import React, { useEffect } from 'react';
import DetailPresenter from 'pages/other/S1/seungminUSB/detail/detailPresenter';
import DataList from 'assets/data/other/S1/seungminUSB.json';

export interface DataIState {
  index: number;
  title: string;
  type: string;
}

const DetailContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clueIdx = parseInt(window.location.pathname.split('/')[4]);

  const data = DataList[clueIdx];

  return <DetailPresenter data={data} />;
};
export default DetailContainer;
