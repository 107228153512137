import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  flex-direction: column;
`;
const InputContainer = styled.div`
  width: 80%;
`;
const Input = styled.input`
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 19px;
  text-align: center;
  letter-spacing: 15px;
  margin-right: -15px;
  color: transparent;
  text-shadow: 0 0 0 white;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface PasswordIState {
  password: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const PasswordInput = ({
  password,
  InputRef,
  handleInputChange,
}: PasswordIState) => {
  return (
    <Container>
      <InputContainer>
        <Input
          type="number"
          name="password"
          placeholder="_____"
          value={password}
          onChange={handleInputChange}
          ref={InputRef}
        />
      </InputContainer>
    </Container>
  );
};
export default PasswordInput;
