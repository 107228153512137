import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 90%;
  height: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '2rem' : '4rem'};
  font-size: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '15px' : '1.8em'};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);

  border-radius: 1px solid rgba(0, 0, 0, 0.8);

  text-align: center;
  letter-spacing: 5px;
  margin-right: -5px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface PasswordIState {
  checkTablet: boolean;
  name: string;
  placeholder: string;
  value: string;
  type: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const Password = ({
  checkTablet,
  name,
  placeholder,
  value,
  type,
  handleInputChange,
}: PasswordIState) => {
  return (
    <Container>
      <Input
        checkTablet={checkTablet}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        type={type}
      />
    </Container>
  );
};
export default Password;
