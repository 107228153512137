import React, { useState, useRef } from 'react';
import CluePresenter from 'pages/hack/S1/gyeongsusblackbox/cluePresenter';

const DetailContainer = () => {
  const [showPw, setShowPw] = useState(false);
  const [Id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const InputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setErrorMessage('');
    if (name === 'Id') {
      setId(value);
    } else if (name === 'Password') {
      setPassword(value);
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (Id !== 'gyeongsu_0415') {
      setErrorMessage('아이디를 확인해주세요');
      return;
    } else if (
      Id === 'gyeongsu_0415' &&
      (password === '73zk4372' ||
        password === '73카4372' ||
        password === '73ㅋㅏ4372')
    ) {
      setShowPw(true);
      return;
    }
    setErrorMessage('비밀번호를 확인해주세요');
    return;
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <CluePresenter
      showPw={showPw}
      Id={Id}
      password={password}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      handleKeyPress={handleKeyPress}
      InputRef={InputRef}
    />
  );
};
export default DetailContainer;
