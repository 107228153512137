import Data from 'assets/data/Data.json';
import { DataIState } from 'types/data';

export function getDetailInformation() {
  let dataList: DataIState[] = [];
  let flag = false;
  let nextTitle = '';
  let beforeTitle = '';
  const target = window.location.pathname.split('/')[1];
  const clueIdx = parseInt(window.location.pathname.split('/')[3]);

  for (let seasonNum = 0; seasonNum < Data.length; seasonNum++) {
    let eachSeason = Data[seasonNum]['episodes'];
    for (let episodeNum = 0; episodeNum < eachSeason.length; episodeNum++) {
      if (target === eachSeason[episodeNum]['url']) {
        dataList = eachSeason[episodeNum]['dataList'];
        if (dataList.length <= clueIdx + 1) {
          nextTitle = '';
        } else {
          nextTitle = dataList[clueIdx + 1]['title'];
        }
        if (clueIdx === 0) {
          beforeTitle = '';
        } else {
          beforeTitle = dataList[clueIdx - 1]['title'];
        }
        flag = true;
        break;
      }
    }
    if (flag) break;
  }

  return {
    index: dataList[clueIdx]['index'],
    title: dataList[clueIdx]['title'],
    description: dataList[clueIdx]['description'],
    dataType: dataList[clueIdx]['dataType'],
    url: target,
    beforeTitle,
    nextTitle,
  };
}
