import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import MainPage from 'pages/hack/S1/laptopPhoto02/laptop/main';
import Carousel from 'pages/hack/S1/laptopPhoto02/laptop/carousel';

const SmartWatch = ({
  showPwBox,
  showPw,
  password,
  wrongPw,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  handleShowPwBox,
  handleBlockPwBox,
  inputRef,
}: WatchIState) => {
  const { isPC } = useSelector(
    ({ responsive }: { responsive: responsiveIState }) => responsive.screenType,
  );

  return (
    <>
      <WatchDivBox checkTablet={!isPC}>
        <Background />
        <PasswordPageContainer PasswordState={showPw}>
          <MainPage
            checkTablet={!isPC}
            showPwBox={showPwBox}
            password={password}
            wrongPw={wrongPw}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleKeyPress={handleKeyPress}
            handleShowPwBox={handleShowPwBox}
            handleBlockPwBox={handleBlockPwBox}
            inputRef={inputRef}
          />
        </PasswordPageContainer>
        {showPw && (
          <PageContainer PasswordState={showPw}>
            <PageBox>
              <Carousel />
            </PageBox>
          </PageContainer>
        )}
      </WatchDivBox>
    </>
  );
};
export default SmartWatch;

interface WatchIState {
  showPwBox: boolean;
  showPw: boolean;
  password: string;
  wrongPw: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  handleShowPwBox: () => void;
  handleBlockPwBox: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const WatchDivBox = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? `${window.innerWidth}` : '900'};
  height: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '120vw' : '460px'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  margin: 5rem 0;
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
`;

const PasswordPageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
  color: white;
`;
const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '1' : '0'};
  z-index: ${(props) => (props.PasswordState ? '1' : '-1')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
`;
const PageBox = styled.div`
  width: 100%;
  height: 100%;
`;
