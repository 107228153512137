import React from 'react';
import styled from 'styled-components';

import Password from 'pages/hack/S1/bohyunHotel/hotel/password';

import cctv from 'assets/clues/S1/hack/04_hotelCCTV.jpg';

const HotelContainer = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? window.innerWidth : 900};
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  overflow: hidden;
  margin: 3rem 0;
`;

const PasswordPage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.4s ease-in-out, z-index 0.4s ease-in-out;

  background-color: white;
  color: black;
`;

const PhotoContainer = styled.div`
  width: 100%;
  height: 550px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Photo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-image: url(${cctv});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 0;
`;

interface PasswordIState {
  showPw: boolean;
  password: string;
  checkTablet: boolean;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const HotelPage = ({
  showPw,
  password,
  checkTablet,
  errorMessage,
  handleInputChange,
  InputRef,
}: PasswordIState) => {
  return (
    <>
      <HotelContainer checkTablet={checkTablet}>
        <PasswordPage PasswordState={showPw}>
          <Password
            password={password}
            checkTablet={checkTablet}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            InputRef={InputRef}
          />
        </PasswordPage>
        {showPw && (
          <PhotoContainer>
            <Photo />
          </PhotoContainer>
        )}
      </HotelContainer>
    </>
  );
};
export default HotelPage;
