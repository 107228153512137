import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  withRouter,
  RouteComponentProps,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import styled from 'styled-components';

import Blossom from 'components/loading/blossom';
import PATH from 'constants/path';

/************** Season 1 **************/
import s1MurderArticle from 'routes/detail/manualDetail/S1/e1MurderArticle';
import s1GroupTalk from 'routes/detail/manualDetail/S1/e1GroupTalk';
import s1GroupTalk02 from 'routes/detail/manualDetail/S1/e1GroupTalk02';
import jiyeonNote from 'routes/hack/S1/jiyeonNote';
import s1SewonJiyeon from 'routes/detail/manualDetail/S1/e1SewonJiyeonTalk';
import seungminMessenger from 'routes/hack/S1/seungminMessenger';
import laptopPhoto from 'routes/hack/S1/laptopPhoto';
import laptopPhoto02 from 'routes/hack/S1/laptopPhoto02';
import jiyeonSmartWatch from 'routes/hack/S1/jiyeonSmartWatch';
import seungminUSBButton from 'routes/detail/manualDetail/S1/e2SeungminUSB';
import seungminUSB from 'routes/other/S1/seungminUSB';
import seungminUSBPw from 'routes/other/S1/seungminUSB/password';
import seungminUSBDetail from 'routes/other/S1/seungminUSB/detail';
import bohyunHotel from 'routes/hack/S1/bohyunHotel';
import jinchulRecoding from 'routes/hack/S1/jinchulRecoding';
import gyeongsuBlackbox from 'routes/hack/S1/gyeongsusblackbox';
import sewonCard from 'routes/hack/S1/sewonCard';

import s1_ep2_question from 'routes/report/question/manualQuestion/S1/ep2_question';
import s1_ep3_question from 'routes/report/question/manualQuestion/S1/ep3_question';
import s1_ep4_question from 'routes/report/question/manualQuestion/S1/ep4_question';
import s1_ep4_result from 'routes/report/result/manualResult/S1/ep4_result';

import PageTransition from 'routes/transition';

const Home = lazy(() => import('routes/home'));
const Season01_list = lazy(() => import('routes/report/clues/S01'));
const List = lazy(() => import('routes/report/clues'));

const Season01_question = lazy(() => import('routes/report/question/S01'));
const Question = lazy(() => import('routes/report/question'));
const Season01_answer = lazy(() => import('routes/report/answer/S01'));
const WrongAnswer = lazy(() => import('routes/report/answer/wrong'));
const CorrectAnswer = lazy(() => import('routes/report/answer/correct'));
const Result = lazy(() => import('routes/report/result/S01'));
const Detail = lazy(() => import('routes/detail'));
const Header = lazy(() => import('components/header'));

/************** Season 2 **************/

const PoliceDataArchive_S02_E01 = lazy(
  () => import('routes/hack/season02/episode01/policeDataArchive'),
);
const KOInsurance_S02_E01 = lazy(
  () => import('routes/hack/season02/episode01/KOInsurance'),
);
const Wedding_S02_E01 = lazy(
  () => import('routes/hack/season02/episode01/wedding'),
);
const EunjungPhone_S02_E01 = lazy(
  () => import('routes/hack/season02/episode01/eunjungPhone'),
);

const EunjungPhone_S02_E02 = lazy(
  () => import('routes/hack/season02/episode02/eunjungPhone'),
);
const BumjunInvestigation_S02_E02 = lazy(
  () => import('routes/hack/season02/episode02/bumjunInvestigation'),
);
const BankIntranet_S02_E02 = lazy(
  () => import('routes/hack/season02/episode02/bankIntranet'),
);
const JihyePhone_S02_E02 = lazy(
  () => import('routes/hack/season02/episode02/jihyePhone'),
);

const NoMore = lazy(() => import('pages/stage/noMore'));
const S2_5_CHAPTER1 = lazy(() => import('pages/stage/chapter1'));
const S2_5_CHAPTER2 = lazy(() => import('pages/stage/chapter2'));
const S2_5_CHAPTER3 = lazy(() => import('pages/stage/chapter3'));
const S2_5_CHAPTER4 = lazy(() => import('pages/stage/chapter4'));

const headerLessComponent = [
  '/villamurder',
  '/vz69w7w9fvv5n4p9kphe/usb',
  '/gj8c4pn64ek27qhbe6e7',
];

const Router: React.FC<RouteComponentProps> = ({ location }) => {
  const [headerLess, setHeaderLess] = useState(false);

  const [showPageIn, setShowPageIn] = useState<boolean>(false);
  const [showPageOut, setShowPageOut] = useState<boolean>(false);

  useEffect(() => {
    setHeaderLess(false);
    let isHeaderLess = !headerLessComponent.every(function (item) {
      return !location.pathname.startsWith(item);
    });
    if (isHeaderLess || location.pathname === '/') {
      setHeaderLess(true);
    }
  }, [location]);

  return (
    <Suspense
      fallback={
        <LoadingContainer>
          <LoadingAnimationContainer>
            <Blossom fill="#222222" />
          </LoadingAnimationContainer>
        </LoadingContainer>
      }
    >
      {headerLess ? null : <Header />}
      <Switch>
        <Route
          path="/"
          exact
          render={() => <Home setShowPageIn={setShowPageIn} />}
        />
        {/**
         * ////////////////////////////////////////
         * ************** Season 1
         * ************* Episode 1
         * ////////////////////////////////////////
         */}
        <Route
          path="/eb65x08b34mpemnsne92/detail/0"
          exact
          component={s1MurderArticle}
        />
        <Route
          path="/eb65x08b34mpemnsne92/detail/2"
          exact
          component={s1GroupTalk}
        />
        <Route
          path="/eb65x08b34mpemnsne92/detail/4"
          exact
          component={jiyeonNote}
        />
        <Route
          path="/eb65x08b34mpemnsne92/detail/5"
          exact
          component={s1SewonJiyeon}
        />
        <Route
          path="/eb65x08b34mpemnsne92/detail/6"
          exact
          component={seungminMessenger}
        />
        <Route
          path="/eb65x08b34mpemnsne92/detail/7"
          exact
          component={laptopPhoto}
        />
        <Route
          path="/eb65x08b34mpemnsne92/data"
          exact
          component={Season01_list}
        />
        <Route path="/:code/QKATX678" exact component={Season01_answer} />
        <Route path="/:code/QKATX678/result" exact component={Result} />
        <Route path="/:code/2KT38T2K" exact component={Season01_answer} />
        <Route
          path="/eb65x08b34mpemnsne92/question"
          exact
          component={Season01_question}
        />
        {/**
         * ////////////////////////////////////////
         * ************** Season 1
         * ************* Episode 1 _ 02
         * ////////////////////////////////////////
         */}
        <Route
          path="/b8rq3b3dxbpvxz2chb38/detail/0"
          exact
          component={s1MurderArticle}
        />
        <Route
          path="/b8rq3b3dxbpvxz2chb38/detail/2"
          exact
          component={s1GroupTalk02}
        />
        <Route
          path="/b8rq3b3dxbpvxz2chb38/detail/4"
          exact
          component={jiyeonNote}
        />
        <Route
          path="/b8rq3b3dxbpvxz2chb38/detail/5"
          exact
          component={s1SewonJiyeon}
        />
        <Route
          path="/b8rq3b3dxbpvxz2chb38/detail/6"
          exact
          component={seungminMessenger}
        />
        <Route
          path="/b8rq3b3dxbpvxz2chb38/detail/7"
          exact
          component={laptopPhoto02}
        />
        <Route
          path="/b8rq3b3dxbpvxz2chb38/data"
          exact
          component={Season01_list}
        />
        <Route
          path="/b8rq3b3dxbpvxz2chb38/question"
          exact
          component={Season01_question}
        />
        {/**
         * ////////////////////////////////////////
         * ************** Season 1
         * ************* Episode 2
         * ////////////////////////////////////////
         */}
        <Route
          path="/vz69w7w9fvv5n4p9kphe/detail/2"
          exact
          component={jiyeonSmartWatch}
        />
        <Route
          path="/vz69w7w9fvv5n4p9kphe/detail/3"
          exact
          component={seungminUSBButton}
        />
        <Route
          path="/vz69w7w9fvv5n4p9kphe/usb"
          exact
          component={seungminUSBPw}
        />
        <Route
          path="/vz69w7w9fvv5n4p9kphe/usb/f2ebuv"
          exact
          component={seungminUSB}
        />
        <Route
          path="/vz69w7w9fvv5n4p9kphe/usb/f2ebuv/:id"
          exact
          component={seungminUSBDetail}
        />
        <Route
          path="/vz69w7w9fvv5n4p9kphe/data"
          exact
          component={Season01_list}
        />
        <Route
          path="/vz69w7w9fvv5n4p9kphe/question"
          exact
          component={s1_ep2_question}
        />
        <Route path="/:code/PGTLFM6S" exact component={Season01_answer} />
        <Route path="/:code/PGTLFM6S/result" exact component={Result} />
        <Route path="/:code/5VSRG4WQ" exact component={Season01_answer} />
        {/**
         * ////////////////////////////////////////
         * ************** Season 1
         * ************* Episode 3
         * ////////////////////////////////////////
         */}
        <Route
          path="/dt2eb65esy3s8mtx7nbd/data"
          exact
          component={Season01_list}
        />
        <Route
          path="/dt2eb65esy3s8mtx7nbd/question"
          exact
          component={s1_ep3_question}
        />
        <Route path="/:code/7JU6RXRF" exact component={Season01_answer} />
        <Route path="/:code/7JU6RXRF/result" exact component={Result} />
        <Route path="/:code/Q3Y2ZSTL" exact component={Season01_answer} />
        {/**
         * ////////////////////////////////////////
         * ************** Season 1
         * ************* Episode 4
         * ////////////////////////////////////////
         */}
        <Route
          path="/fbh5kyy963gzhv2nzppn/detail/2"
          exact
          component={bohyunHotel}
        />
        <Route
          path="/fbh5kyy963gzhv2nzppn/detail/3"
          exact
          component={sewonCard}
        />
        <Route
          path="/fbh5kyy963gzhv2nzppn/detail/4"
          exact
          component={jinchulRecoding}
        />
        <Route
          path="/fbh5kyy963gzhv2nzppn/detail/6"
          exact
          component={gyeongsuBlackbox}
        />
        <Route
          path="/fbh5kyy963gzhv2nzppn/data"
          exact
          component={Season01_list}
        />
        <Route
          path="/fbh5kyy963gzhv2nzppn/question"
          exact
          component={s1_ep4_question}
        />
        <Route path="/:code/E8UCK6ZP" exact component={s1_ep4_result} />
        <Route path="/:code/MC7LV8U8" exact component={Season01_answer} />
        {/**
         * ////////////////////////////////////////
         * ************** Season 2
         * ************* Episode 1
         * ////////////////////////////////////////
         */}
        <Route
          path="/tnw97x6e7898b8mfy8dx/detail/0"
          exact
          component={PoliceDataArchive_S02_E01}
        />
        <Route
          path="/tnw97x6e7898b8mfy8dx/detail/1"
          exact
          component={KOInsurance_S02_E01}
        />
        <Route
          path="/tnw97x6e7898b8mfy8dx/detail/2"
          exact
          component={Wedding_S02_E01}
        />
        <Route
          path="/tnw97x6e7898b8mfy8dx/detail/3"
          exact
          component={EunjungPhone_S02_E01}
        />
        <Route path="/:code/OP3U47CK" exact component={CorrectAnswer} />
        <Route path="/:code/A14ZY2HH" exact component={WrongAnswer} />
        {/**
         * ////////////////////////////////////////
         * ************** Season 2
         * ************* Episode 2
         * ////////////////////////////////////////
         */}
        <Route
          path="/pztezh4ba7w54r6byfbq/detail/0"
          exact
          component={EunjungPhone_S02_E02}
        />
        <Route
          path="/pztezh4ba7w54r6byfbq/detail/1"
          exact
          component={BankIntranet_S02_E02}
        />
        <Route
          path="/pztezh4ba7w54r6byfbq/detail/2"
          exact
          component={BumjunInvestigation_S02_E02}
        />
        <Route
          path="/pztezh4ba7w54r6byfbq/detail/3"
          exact
          component={JihyePhone_S02_E02}
        />
        <Route path="/:code/JT5MFP6E" exact component={CorrectAnswer} />
        <Route path="/:code/HYRV7EQC" exact component={WrongAnswer} />
        <Route path="/:code/JCA8LXVP" exact component={NoMore} />
        {/**
         * ////////////////////////////////////////
         * ************** Season 2
         * ************* Episode 2
         * ////////////////////////////////////////
         */}
        <Route
          path={[PATH.SEASON_2_5_STAGE_1]}
          exact
          render={() => (
            <S2_5_CHAPTER1
              setShowPageIn={setShowPageIn}
              setShowPageOut={setShowPageOut}
            />
          )}
        />
        <Route
          path={[PATH.SEASON_2_5_STAGE_2]}
          exact
          render={() => (
            <S2_5_CHAPTER2
              setShowPageIn={setShowPageIn}
              setShowPageOut={setShowPageOut}
            />
          )}
        />
        <Route
          path={[PATH.SEASON_2_5_STAGE_3]}
          exact
          render={() => (
            <S2_5_CHAPTER3
              setShowPageIn={setShowPageIn}
              setShowPageOut={setShowPageOut}
            />
          )}
        />
        <Route
          path={[PATH.SEASON_2_5_STAGE_4]}
          exact
          render={() => (
            <S2_5_CHAPTER4
              setShowPageIn={setShowPageIn}
              setShowPageOut={setShowPageOut}
            />
          )}
        />
        <Route path="/:code/UF7T4B" exact component={CorrectAnswer} />
        {/**
         * ////////////////////////////////////////
         * ************** Season 공통
         * ////////////////////////////////////////
         */}
        <Route path="/:code/data" exact component={List} />
        <Route path="/:code/detail/:id" exact component={Detail} />
        <Route path="/:code/question" exact component={Question} />
        <Redirect from="*" to="/" />
      </Switch>
      {showPageIn && <PageTransition pageIn={true} />}
      {location.pathname.startsWith(PATH.SEASON_2_5) && showPageOut && (
        <PageTransition pageIn={false} />
      )}
    </Suspense>
  );
};

export default withRouter(Router);

const LoadingContainer = styled.div`
  width: 100%;
  height: 60vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingAnimationContainer = styled.div`
  width: 20%;
  height: 20%;
`;
