import React from 'react';
import styled from 'styled-components';
import cardSpecification from 'assets/clues/S1/hack/04_cardSpecification.jpg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${cardSpecification});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

interface CertificationInfo {}

const CertificationInfo = () => {
  return (
    <>
      <Container>
        <ImageContainer />
      </Container>
    </>
  );
};
export default CertificationInfo;
