import React from 'react';
import styled, { keyframes } from 'styled-components';
import nightsky from 'assets/clues/S1/hack/01_jiyeonNoteBackground.jpg';
import Button1 from 'assets/clues/buttons/phoneButtons/1.png';
import Button2 from 'assets/clues/buttons/phoneButtons/2.png';
import Button3 from 'assets/clues/buttons/phoneButtons/3.png';
import Button4 from 'assets/clues/buttons/phoneButtons/4.png';
import Button5 from 'assets/clues/buttons/phoneButtons/5.png';
import Button6 from 'assets/clues/buttons/phoneButtons/6.png';
import Button7 from 'assets/clues/buttons/phoneButtons/7.png';
import Button8 from 'assets/clues/buttons/phoneButtons/8.png';
import Button9 from 'assets/clues/buttons/phoneButtons/9.png';
import Button0 from 'assets/clues/buttons/phoneButtons/0.png';
import ButtonEnter from 'assets/clues/buttons/phoneButtons/enter.png';
import ButtonBack from 'assets/clues/buttons/phoneButtons/back.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const BackgroundImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${nightsky});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(0.3px) opacity(45%);
  border: none;
  z-index: -2;
`;
const PasswordHint = styled.div`
  position: absolute;
  top: 60px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
`;
const PasswordContainer = styled.div`
  width: 70%;
  height: 2rem;
  position: absolute;
  top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-weight: 500;
  border-radius: 17px;
  :focus {
    background-color: rgba(255, 255, 255, 0.2);
    outline: none;
  }
`;
const shake = keyframes`
  0%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  25%{
    transform: translateX(3px);
    font-weight: 700;
  }
  50%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  75%{
    transform: translateX(2px);
    font-weight: 700;
  }
  100%{
    transform: translateX(0px);
  }
`;
const ErrorMessage = styled.div`
  position: absolute;
  top: 140px;
  color: red;
  font-size: 12px;
  animation: ${shake} 0.2s linear;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Buttons = styled.div`
  display: grid;
  position: absolute;
  top: 200px;
  grid-template-rows: repeat(4);
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 18px;
  grid-column-gap: 18px;
`;
const Button = styled.div`
  width: 58px;
  height: 58px;
  background-image: ${(props: { Image: string }) => `url(${props.Image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
`;

interface PasswordIState {
  password: string;
  errorMessage: string;
  handleNumberClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSubmit: () => void;
}

const PhoneNoteInputBox = ({
  password,
  errorMessage,
  handleNumberClick,
  handleSubmit,
}: PasswordIState) => {
  return (
    <Container>
      <BackgroundImg />
      <PasswordHint>우리 천사 탄신일</PasswordHint>
      <PasswordContainer>{password}</PasswordContainer>
      {errorMessage === '' ? null : <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonContainer>
        <Buttons>
          <Button onClick={handleNumberClick} id="1" Image={Button1} />
          <Button onClick={handleNumberClick} id="2" Image={Button2} />
          <Button onClick={handleNumberClick} id="3" Image={Button3} />
          <Button onClick={handleNumberClick} id="4" Image={Button4} />
          <Button onClick={handleNumberClick} id="5" Image={Button5} />
          <Button onClick={handleNumberClick} id="6" Image={Button6} />
          <Button onClick={handleNumberClick} id="7" Image={Button7} />
          <Button onClick={handleNumberClick} id="8" Image={Button8} />
          <Button onClick={handleNumberClick} id="9" Image={Button9} />
          <Button onClick={handleNumberClick} id="del" Image={ButtonBack} />
          <Button onClick={handleNumberClick} id="0" Image={Button0} />
          <Button onClick={handleSubmit} id="enter" Image={ButtonEnter} />
        </Buttons>
      </ButtonContainer>
    </Container>
  );
};
export default PhoneNoteInputBox;
