import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import blueIALogo from 'assets/clues/S1/other/seungminUSB/etc/blueIALogo.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const Header: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <Container>
      <CenterAlign>
        <div />
        <Symbol
          onClick={() => {
            history.push('/vz69w7w9fvv5n4p9kphe/usb/f2ebuv');
          }}
        >
          <Logo />
        </Symbol>
        <EmptyMenu />
        <Menu
          onClick={() => {
            history.push('/vz69w7w9fvv5n4p9kphe/usb');
          }}
        >
          <ExitToAppIcon />
        </Menu>
      </CenterAlign>
    </Container>
  );
};

export default withRouter(Header);

const Container = styled.div`
  position: relative;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  font-weight: 500;
  background-color: rgba(250, 250, 250, 1);
  padding: 0 10px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
`;

const CenterAlign = styled.div`
  width: 100%;
  max-width: 1100px;
  display: grid;
  grid-template-rows: repeat(1, 50px);
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 10px;
`;
const Symbol = styled.div`
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  grid-column: span 2;
`;

const Logo = styled.div`
  display: inline-block;
  width: 100%;
  height: 80%;
  background: url(${blueIALogo}) no-repeat center;
  background-size: contain;
`;
const Menu = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.78);
  cursor: pointer;
`;
const EmptyMenu = styled.div`
  grid-column: span 2;
`;
