import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
`;

const fade = keyframes`
  from {
      opacity: 0.4
    } 
    to {
      opacity: 1
    }
`;

const Photo = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props: { image: string }) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-animation-name: ${fade};
  -webkit-animation-duration: 1.5s;
  animation-name: ${fade};
  animation-duration: 1.5s;
`;

interface SlideIState {
  photo: string;
}

const Slide = ({ photo }: SlideIState) => {
  return (
    <>
      <Container>
        <Photo image={photo} />
      </Container>
    </>
  );
};
export default Slide;
