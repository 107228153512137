import React from 'react';
import styled from 'styled-components';

import Template from 'components/detail/template';

const DetailPresenter = () => {
  return (
    <Template>
      <ButtonContainer>
        <LinkContainer>
          <LinkToInvestigationReport
            onClick={() => {
              window.open('/vz69w7w9fvv5n4p9kphe/usb');
            }}
          >
            USB 접속
          </LinkToInvestigationReport>
        </LinkContainer>
      </ButtonContainer>
    </Template>
  );
};
export default DetailPresenter;

const ButtonContainer = styled.div`
  max-width: 900px;
  display: flex;
  justify-content: center;
  margin: 3rem 0;
`;

const LinkContainer = styled.div`
  width: 250px;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LinkToInvestigationReport = styled.div`
  width: 50%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
`;
