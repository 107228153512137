import React from 'react';
import styled, { keyframes } from 'styled-components';
import Input from 'pages/hack/S1/jinchulRecoding/password/input';
import goldLoanLogo from 'assets/clues/S1/hack/04_goldLoanLogo.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${(props: { isPC: boolean }) => (props.isPC ? '1.2' : '2.5')}rem;
`;
const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(1, 1fr);
`;

const LogoAlign = styled.div`
  width: 100%;
  height: 100%;
  grid-row: span 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${goldLoanLogo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const CenterAlign = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const PasswordBox = styled.div`
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const shake = keyframes`
  0%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  25%{
    transform: translateX(3px);
    font-weight: 700;
  }
  50%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  75%{
    transform: translateX(2px);
    font-weight: 700;
  }
  100%{
    transform: translateX(0px);
  }
`;
const ErrorMessage = styled.div`
  position: absolute;
  bottom: ${(props: { isPC: boolean }) => (props.isPC ? '5%' : '10%')};
  font-size: ${(props: { isPC: boolean }) => (props.isPC ? '1em' : '1.4em')};
  color: #990000;
  animation: ${shake} 0.2s linear;
  margin-top: 1rem;
`;

interface PasswordIState {
  isPC: boolean;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  isPC,
  password,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  InputRef,
}: PasswordIState) => {
  return (
    <Container isPC={isPC}>
      <GridContainer>
        <div />
        <LogoAlign>
          <LogoContainer />
        </LogoAlign>
        <CenterAlign>
          <PasswordBox onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
            <Input
              isPC={isPC}
              name="Password"
              placeholder="Password"
              value={password}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              type="password"
              InputRef={InputRef}
            />
          </PasswordBox>
          {errorMessage === '' ? null : (
            <ErrorMessage isPC={isPC}>{errorMessage}</ErrorMessage>
          )}
        </CenterAlign>
        <div />
      </GridContainer>
    </Container>
  );
};
export default Password;
