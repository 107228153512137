import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import Hotel from 'pages/hack/S1/bohyunHotel/hotel';

import note from 'assets/clues/S1/hack/04_hotelPassword.jpg';
import Template from 'components/detail/template';

const CluePresenter = ({
  showPw,
  password,
  errorMessage,
  handleInputChange,
  InputRef,
}: DataIState) => {
  const checkTablet = useMediaQuery({
    query: '(max-width: 1000px)',
  });

  return (
    <Template>
      <PhotoContainer>
        <Photo imgUrl={note} />
      </PhotoContainer>
      <Hotel
        showPw={showPw}
        password={password}
        checkTablet={checkTablet}
        errorMessage={errorMessage}
        handleInputChange={handleInputChange}
        InputRef={InputRef}
      />
    </Template>
  );
};
export default CluePresenter;
interface DataIState {
  showPw: boolean;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const PhotoContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
`;
const Photo = styled.img`
  max-width: 100%;
  width: 700px;
  height: 0;
  background-image: url(${(props: { imgUrl: string }) => `${props.imgUrl}`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 66.6%;
`;
