import React from 'react';
import styled, { keyframes } from 'styled-components';

import PasswordInput from 'pages/hack/S1/seungminMessenger/password/input';

import chatIcon from 'assets/clues/S1/hack/01_chatIcon.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #001a33;
  align-items: center;
`;
const InputContainer = styled.div`
  width: 200px;
  height: 40px;
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChatIcon = styled.div`
  width: 110px;
  height: 110px;
  position: absolute;
  top: 100px;
  background-image: url(${chatIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const PasswordContainer = styled.div`
  width: 200px;
  height: 160px;
  position: absolute;
  top: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
`;

const shake = keyframes`
  0%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  25%{
    transform: translateX(3px);
    font-weight: 700;
  }
  50%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  75%{
    transform: translateX(2px);
    font-weight: 700;
  }
  100%{
    transform: translateX(0px);
  }
`;
const ErrorMessage = styled.div`
  position: absolute;
  bottom: 70px;
  color: red;
  font-size: 12px;
  animation: ${shake} 0.2s linear;
`;
const PasswordHint = styled.div`
  position: absolute;
  bottom: 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
`;

interface PasswordIState {
  password: string;
  errorMessege: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const PhoneNoteInputBox = ({
  password,
  errorMessege,
  handleInputChange,
  InputRef,
}: PasswordIState) => {
  return (
    <Container>
      <ChatIcon />
      <PasswordContainer>
        <InputContainer>
          <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
            <PasswordInput
              password={password}
              handleInputChange={handleInputChange}
              InputRef={InputRef}
            />
          </form>
        </InputContainer>
        <PasswordHint>변호사 협회 등록번호</PasswordHint>
        {errorMessege === '' ? null : (
          <ErrorMessage>{errorMessege}</ErrorMessage>
        )}
      </PasswordContainer>
    </Container>
  );
};
export default PhoneNoteInputBox;
