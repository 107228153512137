import React, { useState, useEffect, useRef } from 'react';

import Template from 'components/detail/template';
import SeungMinMessenger from 'pages/hack/S1/seungminMessenger/Main';

const DetailHack = () => {
  const [page, setPage] = useState(0);
  const [showPw, setShowPw] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessege, setErrorMessege] = useState('');

  const InputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleSetPw = () => {
      if (password === '18635') {
        if (!InputRef.current) {
          return;
        }
        InputRef.current.blur();
        return setShowPw(true);
      } else if (password.length >= 6) {
        setPassword(password.substring(0, 5));
        return;
      } else if (password.length === 5) {
        setErrorMessege('비밀번호를 확인해주세요');
      } else {
        return setErrorMessege('');
      }
    };
    handleSetPw();
  }, [password]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setErrorMessege('');
    if (name === 'password') {
      setPassword(value);
    }
  };

  return (
    <Template>
      <SeungMinMessenger
        page={page}
        showPw={showPw}
        password={password}
        errorMessege={errorMessege}
        setPage={setPage}
        handleInputChange={handleInputChange}
        InputRef={InputRef}
      />
    </Template>
  );
};
export default DetailHack;
