import React from 'react';
import styled from 'styled-components';

import PasswordPage from 'pages/hack/S1/sewonCard/Card/password';
import CardSpecificationPage from 'pages/hack/S1/sewonCard/Card/main';

let checkTablet = true;
function checkWidth() {
  checkTablet = true;
  if (window.matchMedia('( min-width: 700px )').matches) {
    checkTablet = false;
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CardDivBox = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? window.innerWidth : '700'}px;
  height: ${(props) =>
    props.checkTablet ? window.innerWidth * 1.41452 : '990'}px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  overflow: hidden;
  margin: 2rem 0;
`;

const PasswordPageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
  color: white;

  &:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 1);
    z-index: -1;
  }
`;
const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '1' : '0'};
  z-index: ${(props) => (props.PasswordState ? '1' : '-1')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
`;

interface CardIState {
  showPw: boolean;
  password: string;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
}

const CardSpecification = ({
  showPw,
  password,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
}: CardIState) => {
  checkWidth();
  return (
    <Container>
      <CardDivBox checkTablet={checkTablet}>
        <PasswordPageContainer PasswordState={showPw}>
          <PasswordPage
            checkTablet={checkTablet}
            password={password}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleKeyPress={handleKeyPress}
          />
        </PasswordPageContainer>
        <PageContainer PasswordState={showPw}>
          {showPw && <CardSpecificationPage />}
        </PageContainer>
      </CardDivBox>
    </Container>
  );
};
export default CardSpecification;
