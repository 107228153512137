import React from 'react';
import styled from 'styled-components';

import Template from 'components/detail/template';

import group_dialog_01_01 from 'assets/clues/S1/clue/image/01_02_group_dialog.jpg';

const DetailClue = () => {
  return (
    <Template>
      <PhotoAligner>
        <PhotoContainer>
          <Photo />
        </PhotoContainer>
      </PhotoAligner>
    </Template>
  );
};
export default DetailClue;

const PhotoAligner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
`;
const PhotoContainer = styled.div`
  width: 310px;
  height: 550px;
  display: flex;
  justify-content: center;
  overflow: auto;
`;
const Photo = styled.img`
  width: 300px;
  height: 1210px;
  background-color: #dbdbdd;
  background-image: url(${group_dialog_01_01});
  background-repeat: no-repeat;
  background-size: contain;
  overflow: auto;
`;
