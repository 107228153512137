import React from 'react';

import NotePad from 'pages/hack/S1/jiyeonNote/phoneNote';
import Template from 'components/detail/template';

const CluePresenter = ({
  firstPage,
  setPage,
  showPw,
  password,
  errorMessage,
  handleNumberClick,
  handleSubmit,
}: DataIState) => {
  return (
    <Template>
      <NotePad
        pass={showPw}
        firstPage={firstPage}
        setPage={setPage}
        password={password}
        errorMessage={errorMessage}
        handleNumberClick={handleNumberClick}
        handleSubmit={handleSubmit}
      />
    </Template>
  );
};

export default CluePresenter;

interface DataIState {
  firstPage: boolean;
  setPage: React.Dispatch<React.SetStateAction<boolean>>;
  showPw: boolean;
  password: string;
  errorMessage: string;
  handleNumberClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSubmit: () => void;
}
