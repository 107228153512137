import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import jailProfile from 'assets/clues/S1/hack/01_jailProfile.jpg';
import naeunProfile from 'assets/clues/S1/hack/01_naeunProfile.jpg';
import naeunProfile02 from 'assets/clues/S1/hack/01_02_naeunProfile.jpg';
import jiyeonProfile from 'assets/clues/S1/hack/01_jiyeonProfile.jpg';

interface MessengerIState {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Messenger = ({ page, setPage }: MessengerIState) => {
  const location = useLocation();
  const messengerRef = useRef<HTMLDivElement>(null);

  const isRenewal = location.pathname.split('/')[1] === 'b8rq3b3dxbpvxz2chb38';

  useEffect(() => {
    if (messengerRef.current !== null && page !== 0) {
      messengerRef.current.style.transition = 'all 0.2s ease-in-out';
      messengerRef.current.style.transform = 'translateX(-100%)';
    } else if (messengerRef.current !== null) {
      messengerRef.current.style.transition = 'all 0.2s ease-in-out';
      messengerRef.current.style.transform = 'translateX(0%)';
    }
  });
  return (
    <>
      <Container ref={messengerRef}>
        <ActivatedChat>
          <ActivatedTitle>저장된 대화</ActivatedTitle>
          <ActivatedCount>3</ActivatedCount>
        </ActivatedChat>
        <MessegeContainer
          onClick={() => {
            setPage(1);
          }}
        >
          <ListImg image={jailProfile} />
          <ListInformation>
            <ListTitle>재일고등학교 학...</ListTitle>
            <ListDate>2020-09-10</ListDate>
            <ListDesc>
              32기 졸업생 신승민 선배님
              <br />
              9월 18일에 있을 동문회에 참여해...
            </ListDesc>
          </ListInformation>
        </MessegeContainer>
        <MessegeContainer
          onClick={() => {
            setPage(2);
          }}
        >
          <ListImg image={jiyeonProfile} />
          <ListInformation>
            <ListTitle>수민이 엄마</ListTitle>
            <ListDate>2020-08-06</ListDate>
            <ListDesc>
              그리고 오후에 수민이 유치원 상담은
              <br />
              시간내서라도 내가 갔다올테니까...
            </ListDesc>
          </ListInformation>
        </MessegeContainer>
        <MessegeContainer
          onClick={() => {
            setPage(3);
          }}
        >
          <ListImg image={isRenewal ? naeunProfile02 : naeunProfile} />
          <ListInformation>
            <ListTitle>세원이 와이프</ListTitle>
            <ListDate>2020-05-14</ListDate>
            <ListDesc>네,나중에 연락주세요.</ListDesc>
          </ListInformation>
        </MessegeContainer>
        <PlusButton>
          <AddCircleOutlineIcon fontSize="large" />
        </PlusButton>
      </Container>
    </>
  );
};

export default Messenger;

const Container = styled.div`
  width: 50%;
  height: 100%;
  padding-top: 23px;
  display: block;
`;
const ActivatedChat = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
`;
const ActivatedTitle = styled.div`
  width: 94px;
  font-size: 1.2em;
  color: black;
`;
const ActivatedCount = styled.div`
  width: 10%;
  height: 50%;
  font-size: 1em;
  display: flex;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.5);
`;

const MessegeContainer = styled.div`
  width: 305px;
  height: 15%;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  margin: 1rem auto;
`;

const ListImg = styled.div`
  width: 30%;
  height: 100%;
  background-image: url(${(props: { image: string }) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
`;
const ListInformation = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(6, 1fr);
`;
const ListTitle = styled.div`
  grid-column: span 4;
  height: 100%;
  padding: 0.5rem;
  color: black;
`;
const ListDate = styled.div`
  grid-column: span 2;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8em;
  padding: 0.5rem 0.2rem;
`;
const ListDesc = styled.div`
  grid-column: span 6;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.7em;
  line-height: 1rem;
  padding: 0.2rem 0.5rem;
`;

const PlusButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-top: 3rem;
  cursor: not-allowed;
`;
