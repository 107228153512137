import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PwPresenter from 'pages/other/S1/seungminUSB/password/PwPresenter';

const PwContainer: React.FC<RouteComponentProps> = ({ history }) => {
  const [password, setPassword] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => setShowMessage(!showMessage), 2500);
    }
  }, [showMessage]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (password.toLowerCase() === 'divorce') {
      history.push('/vz69w7w9fvv5n4p9kphe/usb/f2ebuv');
    } else {
      setPassword('');
      setShowMessage(true);
    }
  }

  function handleUpdate(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  return (
    <PwPresenter
      password={password}
      showMessage={showMessage}
      handleUpdate={handleUpdate}
      handleSubmit={handleSubmit}
    />
  );
};
export default withRouter(PwContainer);
