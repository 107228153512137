import React from 'react';
import styled from 'styled-components';

import Password from 'pages/hack/S1/seungminMessenger/password';
import Messenger from 'pages/hack/S1/seungminMessenger/messenger';

import passwordStatusBar from 'assets/clues/S1/hack/01_statusBarBlack_SM.jpg';
import chatStatusBar from 'assets/clues/S1/hack/01_statusBarGray_SM.jpg';

const Container = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PasswordContainer = styled.div`
  position: absolute;
  width: 310px;
  height: 550px;

  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '0' : '1'};
  z-index: ${(props) => (props.PasswordState ? '-1' : '2')};
  transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
`;

const PasswordStatusBar = styled.div`
  width: 310px;
  height: 21px;
  position: absolute;
  top: 0;
  background-color: rgba(1, 1, 1, 1);
  background-image: url(${(props: { image: string }) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 3;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
`;
const MessengerContainer = styled.div`
  width: 310px;
  height: 550px;
  position: absolute;
  overflow: hidden;
  opacity: ${(props: { PasswordState: boolean }) =>
    props.PasswordState ? '1' : '0'};
  z-index: ${(props) => (props.PasswordState ? '1' : '-1')};
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  background-color: rgba(239, 239, 239, 1);
`;

interface MainIState {
  page: number;
  showPw: boolean;
  password: string;
  errorMessege: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}

const MainContainer = ({
  page,
  showPw,
  password,
  errorMessege,
  setPage,
  handleInputChange,
  InputRef,
}: MainIState) => {
  return (
    <Container>
      <PasswordContainer PasswordState={showPw}>
        <PasswordStatusBar image={passwordStatusBar} />
        <Password
          password={password}
          errorMessege={errorMessege}
          handleInputChange={handleInputChange}
          InputRef={InputRef}
        />
      </PasswordContainer>
      {showPw && (
        <MessengerContainer PasswordState={showPw}>
          <PasswordStatusBar image={chatStatusBar} />
          <Messenger page={page} setPage={setPage} />
        </MessengerContainer>
      )}
    </Container>
  );
};
export default MainContainer;
