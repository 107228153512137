import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const globalStyles = createGlobalStyle`
    ${reset};
    a{
        text-decoration:none;
        color:inherit;
    }
    *{
        padding: 0;
        margin: 0;
        box-sizing:border-box;
    }

    body{ 
        width: 100%;
        height: 100%;

        font-family: 'Noto Sans KR', sans-serif;
        font-size: 15px;  
        font-weight: 400;
        
            
        background-color: rgba(12, 12, 12, 1);            
        color: white;
        padding: 0px;
        
        overflow-x:hidden;
        
        &::-webkit-scrollbar {
            width: 20px;
            background-color: #0C0C0C;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: black;
            background-clip: padding-box;
            
            border: 2px solid transparent;
            border-radius: 10px;
        }
        
       

    }
`;

export default globalStyles;
