import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${(props: { image: string }) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

interface DiaryIState {
  image: string;
}

const DiaryPage = ({ image }: DiaryIState) => {
  return <Container image={image} />;
};
export default DiaryPage;
