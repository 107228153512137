import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
`;

const Input = styled.input`
  width: 90%;
  height: 28px;
  font-size: 15px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  border-radius: 15px;
  margin: 7px 0;
  padding-left: 10px;
  &:focus {
    outline: none;
  }
  &:valid {
    background-color: #111111;
    outline: none;
    transition: border 22s linear, background-color 2s linear;
  }
`;

interface PasswordIState {
  name: string;
  placeholder: string;
  value: string;
  type: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  name,
  placeholder,
  value,
  type,
  handleInputChange,
  InputRef,
}: PasswordIState) => {
  return (
    <Container>
      <Input
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        type={type}
        ref={InputRef}
      />
    </Container>
  );
};
export default Password;
