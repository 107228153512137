import React from 'react';
import styled, { keyframes } from 'styled-components';
import Input from 'pages/hack/S1/gyeongsusblackbox/password/commonInput';
import BlackBoxText from 'assets/clues/S1/hack/04_blackBoxText.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? 'repeat(17, 1fr)' : 'repeat(10, 1fr)'};
  grid-template-columns: repeat(1, 1fr);
`;
const LogoAlign = styled.div`
  width: 100%;
  grid-row: span
    ${(props: { checkTablet: boolean }) => (props.checkTablet ? '8' : '4')};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BlackBoxText});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const CenterAlign = styled.div`
  width: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '100%' : '900px'};
  grid-row: span ${(props) => (props.checkTablet ? '5' : '4')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const PasswordBox = styled.form`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

const LoginButton = styled.div`
  width: 90%;
  height: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '2rem' : '3rem'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
`;
const shake = keyframes`
  0%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  25%{
    transform: translateX(3px);
    font-weight: 700;
  }
  50%{
    transform: translateX(-3px);
    font-weight: 700;
  }
  75%{
    transform: translateX(2px);
    font-weight: 700;
  }
  100%{
    transform: translateX(0px);
  }
`;
const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #990000;
  animation: ${shake} 0.2s linear;
`;
const PasswordHint = styled.div``;
interface PasswordIState {
  Id: string;
  password: string;
  checkTablet: boolean;
  errorMessage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  Id,
  password,
  checkTablet,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleKeyPress,
  InputRef,
}: PasswordIState) => {
  return (
    <Container checkTablet={checkTablet}>
      <div />
      <LogoAlign checkTablet={checkTablet}>
        <LogoContainer />
      </LogoAlign>
      <CenterAlign checkTablet={checkTablet}>
        <PasswordBox
          onSubmit={handleSubmit}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        >
          <Input
            checkTablet={checkTablet}
            name="Id"
            placeholder="Id"
            value={Id}
            handleInputChange={handleInputChange}
            type="none"
            InputRef={InputRef}
          />
          <Input
            checkTablet={checkTablet}
            name="Password"
            placeholder="Password"
            value={password}
            handleInputChange={handleInputChange}
            type="password"
            InputRef={InputRef}
          />
          <LoginButton checkTablet={checkTablet} onClick={handleSubmit}>
            로그인
          </LoginButton>
        </PasswordBox>
        <PasswordHint>초기 비밀번호는 차량번호입니다</PasswordHint>
      </CenterAlign>
      <ErrorMessage>{errorMessage === '' ? null : errorMessage}</ErrorMessage>
      <div />
      <div />
    </Container>
  );
};
export default Password;
