import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import logo from 'assets/hatsLogo_any.png';
import question_01_03 from 'assets/result/question/question_01_03.png';

const QuestionPresenter = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = React.useState<AnswerFormIState>({
    year: '0000',
    month: '00',
    day: '00',
  });

  const isTablet = useMediaQuery({
    query: '(min-width: 700px)',
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    event.preventDefault();
    const name = event.target.name as string;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const enteredAnswer = `${state.year}-${state.month}-${state.day}`;
    if (enteredAnswer === '') {
      setErrorMessage('정답을 입력해주세요');
    } else if (enteredAnswer === '2019-02-07')
      return history.push(`/dt2eb65esy3s8mtx7nbd/7JU6RXRF`);
    else return history.push(`/dt2eb65esy3s8mtx7nbd/Q3Y2ZSTL`);
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  return (
    <Container>
      <CenterAlign>
        <MissionContainer>
          <Mission src={question_01_03} />
        </MissionContainer>
        <InputBoxAligner
          onSubmit={handleSubmit}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        >
          <InputBoxContainer isTablet={isTablet}>
            <div />
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="year" onChange={handleChange}>
                <Option value="0000">0000년</Option>
                <Option value="2020">2020년</Option>
                <Option value="2019">2019년</Option>
                <Option value="2018">2018년</Option>
                <Option value="2017">2017년</Option>
                <Option value="2016">2016년</Option>
                <Option value="2015">2015년</Option>
                <Option value="2014">2014년</Option>
                <Option value="2013">2013년</Option>
                <Option value="2012">2012년</Option>
              </Select>
            </SelectContainer>
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="month" onChange={handleChange}>
                <Option value="00">00월</Option>
                <Option value="01">01월</Option>
                <Option value="02">02월</Option>
                <Option value="03">03월</Option>
                <Option value="04">04월</Option>
                <Option value="05">05월</Option>
                <Option value="06">06월</Option>
                <Option value="07">07월</Option>
                <Option value="08">08월</Option>
                <Option value="09">09월</Option>
                <Option value="10">10월</Option>
                <Option value="11">11월</Option>
                <Option value="12">12월</Option>
              </Select>
            </SelectContainer>
            <SelectContainer isTablet={isTablet}>
              <Select dir="ltl" name="day" onChange={handleChange}>
                <Option value="00">00일</Option>
                <Option value="01">01일</Option>
                <Option value="02">02일</Option>
                <Option value="03">03일</Option>
                <Option value="04">04일</Option>
                <Option value="05">05일</Option>
                <Option value="06">06일</Option>
                <Option value="07">07일</Option>
                <Option value="08">08일</Option>
                <Option value="09">09일</Option>
                <Option value="10">10일</Option>
                <Option value="11">11일</Option>
                <Option value="12">12일</Option>
                <Option value="13">13일</Option>
                <Option value="14">14일</Option>
                <Option value="15">15일</Option>
                <Option value="16">16일</Option>
                <Option value="17">17일</Option>
                <Option value="18">18일</Option>
                <Option value="19">19일</Option>
                <Option value="20">20일</Option>
                <Option value="21">21일</Option>
                <Option value="22">22일</Option>
                <Option value="23">23일</Option>
                <Option value="24">24일</Option>
                <Option value="25">25일</Option>
                <Option value="26">26일</Option>
                <Option value="27">27일</Option>
                <Option value="28">28일</Option>
                <Option value="29">29일</Option>
                <Option value="30">30일</Option>
                <Option value="31">31일</Option>
              </Select>
            </SelectContainer>
            <div />
            <LogoContainer>
              <Logo onClick={handleSubmit} />
            </LogoContainer>
          </InputBoxContainer>
        </InputBoxAligner>
        <MessageContainer>
          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </MessageContainer>
        <div />
      </CenterAlign>
    </Container>
  );
};
export default QuestionPresenter;

interface AnswerFormIState {
  year: string;
  month: string;
  day: string;
}

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin: 1rem 0 5rem 0;
`;
const CenterAlign = styled.div`
  width: 100%;
  max-width: 900px;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MissionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 60px 0;
`;
const Mission = styled.img`
  min-width: 280px;
  width: 40%;
  max-width: 500px;
`;

const InputBoxAligner = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputBoxContainer = styled.div`
  min-width: 310px;
  width: 80%;
  max-width: 400px;
  height: 130%;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(7, 1fr);

  font-size: ${(props: { isTablet: boolean }) =>
    props.isTablet ? '16' : '12'}px;
  font-weight: bold;
  background-color: #595959;
  padding: 0.5rem 0;
  border-radius: 10px;
`;
const SelectContainer = styled.div`
  min-width: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${(props: { isTablet: boolean }) =>
    props.isTablet ? '0.3' : '0'}rem;
`;

const Select = styled.select`
  height: 100%;
  color: white;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  border: none;

  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

const Option = styled.option`
  text-align: center;
  color: black;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

const MessageContainer = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ErrorMessage = styled.div`
  color: red;
`;
