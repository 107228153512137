import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 90%;
  height: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '1.5rem' : '3rem'};
  font-size: ${(props: { checkTablet: boolean }) =>
    props.checkTablet ? '15px' : '1.4em'};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 1px solid rgba(0, 0, 0, 0.8);
`;

interface PasswordIState {
  checkTablet: boolean;
  name: string;
  placeholder: string;
  value: string;
  type: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  checkTablet,
  name,
  placeholder,
  value,
  type,
  handleInputChange,
  InputRef,
}: PasswordIState) => {
  return (
    <Container>
      <Input
        checkTablet={checkTablet}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        type={type}
        ref={InputRef}
      />
    </Container>
  );
};
export default Password;
