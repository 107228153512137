import React, { useState, useRef } from 'react';

import Template from 'components/detail/template';
import Laptop from 'pages/hack/S1/laptopPhoto02/laptop';

const DetailHack = () => {
  const [showPwBox, setShowPwBox] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [password, setPassword] = useState('');
  const [wrongPw, setWrongPw] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleShowPwBox = () => {
    setShowPwBox(true);
  };

  const handleBlockPwBox = () => {
    setShowPwBox(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    if (name === 'password') {
      setPassword(value);
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (password === '1971') {
      setShowPw(true);
      if (!inputRef.current) {
        return;
      }
      inputRef.current.blur();
      return;
    }
    setWrongPw(true);
    setPassword('');
    setTimeout(function () {
      setWrongPw(false);
    }, 200);
    return;
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <Template>
      <Laptop
        showPwBox={showPwBox}
        showPw={showPw}
        password={password}
        wrongPw={wrongPw}
        handleInputChange={handleInputChange}
        handleKeyPress={handleKeyPress}
        handleSubmit={handleSubmit}
        handleShowPwBox={handleShowPwBox}
        handleBlockPwBox={handleBlockPwBox}
        inputRef={inputRef}
      />
    </Template>
  );
};
export default DetailHack;
