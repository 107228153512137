import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter } from 'react-router-dom';

import Router from 'components/router';
import GlobalStyles from 'components/globalStyles';
import { getScreenType } from 'modules/responsive';

import './fonts.css';

function App() {
  const dispatch = useDispatch();

  const isLargePC = useMediaQuery({ query: '(min-width: 1440px)' });
  const isPC = useMediaQuery({ query: '(min-width: 1024px)' });
  const isTablet = useMediaQuery({
    query: '(min-width: 650px) and (max-width: 1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 649px)',
  });

  useEffect(() => {
    dispatch(
      getScreenType({
        isLargePC,
        isPC,
        isTablet,
        isMobile,
      }),
    );
  });

  return (
    <BrowserRouter>
      <GlobalStyles />
      <Router />
    </BrowserRouter>
  );
}

export default App;
