import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import MessengerList from 'pages/hack/S1/seungminMessenger/messenger/list';
import Footer from 'pages/hack/S1/seungminMessenger/messenger/footer';

import jailHS from 'assets/clues/S1/hack/01_jailHS.jpg';
import ji_seung from 'assets/clues/S1/hack/01_ji_seung_talk.jpg';
import na_seung from 'assets/clues/S1/hack/01_na_seung_talk.jpg';
import na_seung02 from 'assets/clues/S1/hack/01_02_na_seung_talk.jpg';

const Messenger = ({ page, setPage }: MessengerIState) => {
  const location = useLocation();
  const messengerRef = useRef<HTMLDivElement>(null);

  const isRenewal = location.pathname.split('/')[1] === 'b8rq3b3dxbpvxz2chb38';

  useEffect(() => {
    if (messengerRef.current !== null && page !== 0) {
      messengerRef.current.style.transition = 'all 0.2s ease-in-out';
      messengerRef.current.style.transform = 'translateX(-100%)';
    } else if (messengerRef.current !== null) {
      messengerRef.current.style.transition = 'all 0.2s ease-in-out';
      messengerRef.current.style.transform = 'translateX(0%)';
    }
  });
  return (
    <>
      <Container>
        <MessengerList page={page} setPage={setPage} />
        <MessegeContainer ref={messengerRef} page={page}>
          <MessegeHeaderContainer selected={page === 1}>
            <MessegeHeader>
              <BackButton onClick={() => setPage(0)}>
                <ArrowBackIosRoundedIcon />
              </BackButton>
              <div />
              <HeaderText>재일고등학교 학생회</HeaderText>
            </MessegeHeader>
          </MessegeHeaderContainer>
          <MessegeHeaderContainer selected={page === 2}>
            <MessegeHeader>
              <BackButton onClick={() => setPage(0)}>
                <ArrowBackIosRoundedIcon />
              </BackButton>
              <div />
              <HeaderText>수민이 엄마</HeaderText>
            </MessegeHeader>
          </MessegeHeaderContainer>
          <MessegeHeaderContainer selected={page === 3}>
            <MessegeHeader>
              <BackButton onClick={() => setPage(0)}>
                <ArrowBackIosRoundedIcon />
              </BackButton>
              <div />
              <HeaderText>세원이 와이프</HeaderText>
            </MessegeHeader>
          </MessegeHeaderContainer>
          <PhotoContainer selected={page === 1}>
            <Messege>
              <ShortMessege Img={jailHS} />
            </Messege>
          </PhotoContainer>
          <PhotoContainer selected={page === 2}>
            <Messege>
              <JSMessege Img={ji_seung} />
            </Messege>
          </PhotoContainer>
          <PhotoContainer selected={page === 3}>
            <Messege>
              <NSMessege Img={isRenewal ? na_seung02 : na_seung} />
            </Messege>
          </PhotoContainer>
          <Footer />
        </MessegeContainer>
      </Container>
    </>
  );
};

export default Messenger;

const Container = styled.div`
  width: 200%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;
const MessegeContainer = styled.div`
  width: 50%;
  height: 100%;
  left: 100%;
  overflow: hidden;
  opacity: ${(props: { page: number }) => (props.page === 0 ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
`;

const MessegeHeaderContainer = styled.div`
  width: 100%;
  height: 7%;
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props: { selected: boolean }) => (props.selected ? 1 : 0)};
  display: ${(props: { selected: boolean }) =>
    props.selected ? 'block' : 'none'};
  color: #1a1a1a;
  z-index: 3;
`;
const MessegeHeader = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(10, 1fr);
  background-color: rgb(219, 219, 220);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  z-index: 2;
`;

const BackButton = styled.div`
  margin: auto;
`;
const HeaderText = styled.div`
  grid-column: span 5;
  color: #1a1a1a;
  margin: auto 0;
`;
const PhotoContainer = styled.div`
  width: 310px;
  height: 550px;
  display: ${(props: { selected: boolean }) =>
    props.selected ? 'block' : 'none'};
  background-color: #dbdbdd;
  overflow: auto;
  opacity: ${(props: { selected: boolean }) => (props.selected ? 1 : 0)};
  z-index: ${(props) => (props.selected ? 1 : -1)};
`;
const Messege = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #dbdbdd;
  transition: opacity 0.2s ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ShortMessege = styled.div`
  width: 300px;
  height: 570px;
  background-color: #dbdbdd;
  background-image: url(${(props: { Img: string }) => props.Img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const JSMessege = styled.div`
  width: 300px;
  height: 760px;
  background-image: url(${(props: { Img: string }) => props.Img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const NSMessege = styled.div`
  width: 300px;
  height: 850px;
  background-image: url(${(props: { Img: string }) => props.Img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
interface MessengerIState {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
