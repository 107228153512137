import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 250px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 200, 200, 1);
  border-radius: 5px;
`;

const Input = styled.input`
  width: 80%;
  padding: 0 1.5rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
`;

const Button = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
`;

interface PwPrsenterIState {
  password: string;
  handleUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
}

const PwPresenter = ({
  password,
  handleUpdate,
  handleSubmit,
}: PwPrsenterIState) => {
  return (
    <Container>
      <Input type="password" value={password} onChange={handleUpdate} />
      <Button onClick={handleSubmit}>&#10095;</Button>
    </Container>
  );
};
export default PwPresenter;
