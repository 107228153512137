import React, { useState, useEffect, useRef } from 'react';
import CluePresenter from 'pages/hack/S1/bohyunHotel/cluePresenter';

const DetailContainer = () => {
  const [showPw, setShowPw] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const InputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setErrorMessage('');
    if (name === 'Password') {
      setPassword(value);
      if (value === '22222') {
        setShowPw(true);
        if (!InputRef.current) {
          return;
        }
        InputRef.current.blur();
        return;
      } else if (value.length >= 5) {
        setPassword(value.substring(0, 5));
        setErrorMessage('비밀번호를 확인해주세요');
      }
    }
  };

  return (
    <CluePresenter
      showPw={showPw}
      password={password}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      InputRef={InputRef}
    />
  );
};
export default DetailContainer;
