import React, { useState, useRef } from 'react';

import Template from 'components/detail/template';
import SmartWatch from 'pages/hack/S1/jiyeonSmartWatch/SmartWatch';

const DetailClue = () => {
  const [showPw, setShowPw] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const InputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setErrorMessage('');
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!email.includes('@')) {
      setErrorMessage('아이디는 이메일 형식입니다');
      return;
    } else if (email !== 'jiyeoni1030@kmail.com') {
      setErrorMessage('가입되지 않은 이메일입니다');
      return;
    } else if (
      email === 'jiyeoni1030@kmail.com' &&
      password === 'jiyeon_0415'
    ) {
      setShowPw(true);
      if (!InputRef.current) {
        return;
      }
      InputRef.current.blur();
      return;
    }
    setErrorMessage('비밀번호를 확인해주세요');
    return;
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <Template>
      <SmartWatch
        email={email}
        showPw={showPw}
        password={password}
        errorMessage={errorMessage}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        handleKeyPress={handleKeyPress}
        InputRef={InputRef}
      />
    </Template>
  );
};
export default DetailClue;
