import React from 'react';
import styled from 'styled-components';

import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';

const MessegeFooter = styled.div`
  width: 100%;
  height: 8%;
  position: absolute;
  bottom: 0px;
  display: flex;
  background-color: #f7f7f7;
  justify-content: space-around;
  align-items: center;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
  z-index: 3;
`;

const PasswordInput = () => {
  return (
    <MessegeFooter>
      <AttachFileOutlinedIcon color="disabled" />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <EmojiEmotionsOutlinedIcon color="disabled" />
    </MessegeFooter>
  );
};
export default PasswordInput;
