import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: ${(props: { isPC: boolean }) => (props.isPC ? '50' : '100')}%;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
`;

const Input = styled.input`
  width: 80%;
  height: ${(props: { isPC: boolean }) => (props.isPC ? '1.5rem' : '3rem')};
  font-size: ${(props: { isPC: boolean }) => (props.isPC ? '15px' : '1.4em')};
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0.7);
  border: none;
  outline: none;
  &::focus {
    outline: none;
  }
  margin-left: 5%;
`;

const LoginButton = styled.div`
  width: 10%;
  height: ${(props: { isPC: boolean }) => (props.isPC ? '2rem' : '3rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(12, 12, 12, 0);
  color: white;
  cursor: pointer;
`;

interface PasswordIState {
  isPC: boolean;
  name: string;
  placeholder: string;
  value: string;
  type: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  InputRef: React.RefObject<HTMLInputElement>;
}
const Password = ({
  isPC,
  name,
  placeholder,
  value,
  type,
  handleInputChange,
  handleSubmit,
  InputRef,
}: PasswordIState) => {
  return (
    <Container isPC={isPC}>
      <Input
        isPC={isPC}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        type={type}
        ref={InputRef}
      />
      <LoginButton isPC={isPC} onClick={handleSubmit}>
        &#10095;
      </LoginButton>
    </Container>
  );
};
export default Password;
