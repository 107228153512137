import React, { useState, useEffect } from 'react';
import CluePresenter from 'pages/hack/S1/jiyeonNote/cluePresenter';

const ClueContainer = () => {
  const [firstPage, setPage] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [password]);

  const handleSubmit = () => {
    if (password === '1227') {
      return setShowPw(true);
    } else if (password.length >= 4) {
      setErrorMessage('비밀번호를 확인해주세요');
    } else {
      setErrorMessage('');
    }
  };
  const handleNumberClick = (event: React.MouseEvent<HTMLElement>) => {
    const Id = event.currentTarget.id;
    if (Id === '1') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '2') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '3') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '4') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '5') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '6') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '7') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '8') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '9') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === '0') {
      if (password.length < 4) {
        setPassword(password + Id);
      }
    } else if (Id === 'del') {
      setPassword('');
    } else if (Id === 'enter') {
      if (password.length !== 0) {
        setPassword('');
      }
    }
  };

  return (
    <CluePresenter
      firstPage={firstPage}
      setPage={setPage}
      showPw={showPw}
      password={password}
      errorMessage={errorMessage}
      handleNumberClick={handleNumberClick}
      handleSubmit={handleSubmit}
    />
  );
};
export default ClueContainer;
