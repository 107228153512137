const PATH: { [key: string]: string } = {
  SEASON_2_5: '/gj8c4pn64ek27qhbe6e7',
  SEASON_2_5_STAGE_1: '/gj8c4pn64ek27qhbe6e7/3WWJ549G',
  SEASON_2_5_STAGE_2: '/gj8c4pn64ek27qhbe6e7/6LS3AMMF',
  SEASON_2_5_STAGE_3: '/gj8c4pn64ek27qhbe6e7/SB5VKW4Y',
  SEASON_2_5_STAGE_4: '/gj8c4pn64ek27qhbe6e7/C2L872CG',
  SEASON_2_5_RESULT: '/gj8c4pn64ek27qhbe6e7/UF7T4B',
};

export default PATH;
