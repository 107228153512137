import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import CluePresenter from 'pages/hack/S1/jinchulRecoding/cluePresenter';

const DetailContainer = () => {
  const [showPw, setShowPw] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const InputRef = useRef<HTMLInputElement>(null);

  const { isPC } = useSelector(
    ({ responsive }: { responsive: responsiveIState }) => responsive.screenType,
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    setErrorMessage('');
    if (name === 'Password') {
      setPassword(value);
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (password === '41156') {
      setShowPw(true);
      if (!InputRef.current) {
        return;
      }
      InputRef.current.blur();
      return;
    }
    setErrorMessage('error');
    return;
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <CluePresenter
      isPC={isPC}
      showPw={showPw}
      password={password}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      handleKeyPress={handleKeyPress}
      InputRef={InputRef}
    />
  );
};
export default DetailContainer;
