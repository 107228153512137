import React from 'react';
import styled from 'styled-components';

import Input from 'components/other/S1/input';
import caution from 'assets/clues/S1/other/seungminUSB/etc/caution.jpg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 1);
`;

const CenterAlign = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
`;

const Coution = styled.div`
  width: 100%;
  background-image: url(${caution});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 61.8%;
`;

interface PwPrsenterIState {
  password: string;
  showMessage: boolean;
  handleUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
}

const PwPresenter = ({
  password,
  showMessage,
  handleUpdate,
  handleSubmit,
}: PwPrsenterIState) => {
  return (
    <>
      <Container>
        <CenterAlign>
          <div />
          <Coution />
          <form onSubmit={handleSubmit}>
            <Input
              password={password}
              handleUpdate={handleUpdate}
              handleSubmit={handleSubmit}
            />
          </form>
          <div />
          <div />
          <div />
          <div />
        </CenterAlign>
      </Container>
    </>
  );
};
export default PwPresenter;
