import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DataIState } from 'pages/other/S1/seungminUSB/detail/detailContainer';
import VideoPlayer from 'components/Video';

import logo from 'assets/clues/S1/hack/04_recording.png';
import id_pw_pattern from 'assets/clues/S1/other/seungminUSB/id_pw_pattern.jpg';
import receipt from 'assets/clues/S1/other/seungminUSB/receipt.jpg';
import insurance from 'assets/clues/S1/other/seungminUSB/insurance.jpg';
import record from 'assets/clues/S1/other/seungminUSB/record.mp4';
import diary from 'assets/clues/S1/other/seungminUSB/diary.jpg';

const DetailPresenter = ({ data }: DataPresenterIState) => {
  const history = useHistory();

  let dataMap = new Map();

  dataMap.set(0, id_pw_pattern);
  dataMap.set(1, receipt);
  dataMap.set(2, insurance);
  dataMap.set(3, record);
  dataMap.set(4, diary);

  const videoOptions = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: dataMap.get(data.index),
        type: `${data.type.split('/')[0]}/${data.type.split('/')[1]}`,
      },
    ],
    width:
      window.innerWidth > parseInt(data.type.split('/')[2])
        ? parseInt(data.type.split('/')[2]) > 900
          ? 900
          : data.type.split('/')[2]
        : window.innerWidth,
    poster: logo,
  };
  return (
    <>
      <Container>
        <CenterAlign>
          <ReturnButton
            onClick={() => {
              history.push('/vz69w7w9fvv5n4p9kphe/usb/f2ebuv');
            }}
          >
            &#10094;
          </ReturnButton>
          <TitleContainer>
            <TitleAlign>
              <Title>{data.title}</Title>
            </TitleAlign>
          </TitleContainer>

          {data.type.startsWith('video') ? (
            <DescriptionContainer>
              <VideoPlayer {...videoOptions} />
            </DescriptionContainer>
          ) : (
            <PhotoContainer>
              <Photo
                imgUrl={dataMap.get(data.index)}
                maxWidth={parseFloat(data.type.split('/')[2]) || 0}
                heightRate={parseFloat(data.type.split('/')[3]) || 0}
              />
            </PhotoContainer>
          )}
        </CenterAlign>
      </Container>
    </>
  );
};

export default DetailPresenter;

interface DataPresenterIState {
  data: DataIState;
}

const Container = styled.div`
  width: 100vw;
  min-height: ${window.innerHeight}px;
  display: flex;
  justify-content: center;
  font-family: 'Jua', sans-serif;
  color: black;
  font-weight: 500;
  font-size: 30px;
  background-color: rgba(245, 245, 245, 1);
  padding-bottom: 3rem;
`;
const CenterAlign = styled.div`
  width: 100%;
  max-width: 900px;
  margin-top: 40px;
`;

const ReturnButton = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  margin: 1rem 3rem;
  padding: 1rem;
  border: 3px solid rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;
const TitleContainer = styled.div`
  width: 100%;
  height: 5rem;
  position: relative;
`;

const Title = styled.div`
  width: 80%;

  justify-content: center;
`;

const TitleAlign = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 10%;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PhotoContainer = styled.div`
  max-width: 900px;
  display: flex;
  justify-content: center;
`;
const Photo = styled.img`
  max-width: ${(props: {
    maxWidth: number;
    imgUrl: string;
    heightRate: number;
  }) => props.maxWidth}px;
  width: 100%;
  height: ${(props) => (props.heightRate * window.innerWidth) / 100}px;
  min-height: 500px;
  max-height: ${(props) => props.heightRate * 9}px;
  background-color: black;
  background-image: url(${(props) => `${props.imgUrl}`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
